import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import './submitted-page.scss';
import RadarGraph from "./radar-graph";

function SurveySubmittedPage() {
    const { t } = useTranslation();
    const graph = useSelector(state => state.graph);
    const questionAnswers = useSelector(state => state.questionAnswers);

    const getGraphData = () => {
        // HACK hardcode data
        // we know the format needs to be like {axis:"Digital fitness",value:0.22}
        // answer is array of [{item_id: "multi-item-id-1", answer: 5, title: "Digital Fitness"},..]
        return questionAnswers
            .filter(q => graph.questions.includes(q.questionId))
            .map(q => {
                return q.answer.map(qa => {
                    return {
                        axis: qa.title,
                        value: qa.answer,
                        userData: true,
                    }
                })
            });
    };

    return (
        <div className='survey-submitted-container'>
            {!graph && <h2 className='success-text'>{t("submitSuccess")}</h2>}
            {graph && graph.type === 'radar' &&
                <RadarGraph options={graph} data={getGraphData()}/>
            }
        </div>
    );
}

export default SurveySubmittedPage;
