import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { addPageQuestionError, removePageQuestionError, updateQuestionAnswer } from "../../redux/actions";
import _ from 'lodash';

// Functions for updating answer
const updateAnswer = (answer, textBoxValue, textBoxIndex) => {
    let tempAnswer = _.cloneDeep(answer);
    tempAnswer.splice(textBoxIndex, 1, textBoxValue);
    return tempAnswer;
};

// Functions for removing text box
const removeTextBox = (answer, textBoxIndex) => {
    let tempAnswer = _.cloneDeep(answer);
    tempAnswer.splice(textBoxIndex, 1);
    return tempAnswer;
};

// Functions for adding text box
const addTextBox = (answer) => {
    let tempAnswer = _.cloneDeep(answer);
    tempAnswer.push('');
    return tempAnswer;
};

// Functions for checking if answer has any value
const hasAnswer = (answer) => {
    const nonEmptyFields = answer.filter(a => a && a !== '');
    return nonEmptyFields.length !== 0;
};

// Custom hook for single line text question
const initialFieldNumber = (template) => {
    if (template.initial_number_of_fields >= template.items_min_number || template.items_min_number === undefined) return template.initial_number_of_fields;
    return template.items_min_number
}

// Custom hook for single line text question
const useSingleLineTextQuestion = (template) => {
    const dispatch = useDispatch(); // Get dispatch function from redux
    const language = useSelector(state => state.language); // Get language from redux
    const user = useSelector(state => state.user); // Get user from redux
    const questionAnswers = useSelector(state => state.questionAnswers); // Get question answers from redux
    const requiredError = useSelector(state => state.requiredError); // Get required error from redux
    const pageQuestionErrors = useSelector(state => state.pageQuestionErrors); // Get page question errors from redux
    const [answer, setAnswer] = useState(_.fill(Array(initialFieldNumber(template)), '')); // State for answer
    const [minError, setMinError] = useState(false); // State for min error

    // Debounce function for delayed update
    const delayedUpdate = useCallback(_.debounce(answer => {
        dispatch(updateQuestionAnswer(template.element_id, answer, user.token, questionAnswers))
    }, 300), [dispatch, template.element_id, user.token, questionAnswers]);

    // useEffect for setting min error if items_min_number is set
    useEffect(() => {
        if (template.items_min_number !== undefined && template.validation_min) {
            setMinError(true);
            dispatch(addPageQuestionError(template.element_id));
        }
    }, [template.items_min_number, template.validation_min, dispatch, template.element_id]);

    // useEffect for setting answer when questionAnswers or template changes
    useEffect(() => {
        const questionAnswer = questionAnswers.find(qa => qa.questionId === template.element_id);
        setAnswer(questionAnswer ? questionAnswer.answer : _.fill(Array(initialFieldNumber(template)), ''));
    }, [template, questionAnswers]);

    useEffect(() => {
        if (template.items_min_number && template.validation_min) {
            let nonEmpty = answer.filter(Boolean).length;
            setMinError(nonEmpty < template.items_min_number);

            if (nonEmpty < template.items_min_number && !pageQuestionErrors.includes(template.element_id)) {
                dispatch(addPageQuestionError(template.element_id));
            }
            if (nonEmpty >= template.items_min_number && pageQuestionErrors.includes(template.element_id)) {
                dispatch(removePageQuestionError(template.element_id));
            }
        }
    }, [answer, template.items_min_number, template.validation_min, pageQuestionErrors, dispatch, template.element_id]);

    // Functions for handling text box change, remove and add
    const handleTextBoxChange = (event, index) => {
        console.log(`textBox changed! value: ${event.target.value}, index: ${index}`);
        const newAnswer = updateAnswer(answer, event.target.value, index);
        setAnswer(newAnswer);
        delayedUpdate(newAnswer);
    };

    // Function for handling text box remove
    const handleTextBoxRemove = (index) => {
        if (template.validation_min && answer.length <= template.items_min_number) {
            console.log(`Cannot remove textBox ${index} because it would violate the minimum number of items`);
            return;
        }
        console.log(`textBox ${index} remove`);
        const newAnswer = removeTextBox(answer, index);
        setAnswer(newAnswer);
        dispatch(updateQuestionAnswer(template.element_id, newAnswer, user.token, questionAnswers));
    };

    // Function for handling text box add
    const handleTextBoxAdd = () => {
        if (template.validation_max && answer.length >= template.items_max_number) {
            console.log(`Cannot add more textBoxes because it would exceed the maximum number of items`);
            return;
        }
        console.log(`textBox add`);
        const newAnswer = addTextBox(answer);
        setAnswer(newAnswer);
    };

    // Return necessary variables and functions
    return {
        answer,
        minError,
        language,
        handleTextBoxChange,
        handleTextBoxRemove,
        handleTextBoxAdd,
        requiredError,
        hasAnswer
    }
};

export default useSingleLineTextQuestion;
