import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    addAdditionalItem,
    removeAdditionalItem,
    updateQuestionAnswer,
    addPageQuestionError,
    removePageQuestionError,
} from "../../redux/actions";
import _ from 'lodash';

const uuid = require('uuid'); // For generating unique ids

// Function to get the new question answer
const getNewQuestionAnswer = (answer, itemAnswer, itemId, itemTitle) => {
    let newAnswer = _.cloneDeep(answer);
    const itemAnswerIndex = answer.findIndex(a => a.item_id === itemId);
    if (itemAnswerIndex !== -1) {
        newAnswer[itemAnswerIndex].answer = itemAnswer;
        newAnswer[itemAnswerIndex].title = itemTitle;
    } else {
        newAnswer.push({
            item_id: itemId,
            answer: itemAnswer,
            title: itemTitle
        })
    }

    console.log('new answer: ', newAnswer);
    return newAnswer;
};

// Function to remove an answer
const removeAnswer = (answer, itemId) => {
    let newAnswer = _.cloneDeep(answer);
    _.remove(newAnswer, a => a.item_id === itemId);
    return newAnswer;
};

// Function to get the sum of the answers
const getAnswerSum = (answer) => {
    let sum = 0;
    answer.forEach(itemAnswer => {
        if (itemAnswer.answer) {
            sum += itemAnswer.answer;
        }
    });
    return sum;
};

// Function to filter the answer
const filterAnswer = (answer, allItems) => {
    if (allItems && allItems.length) {
        let newAnswer = _.cloneDeep(answer);
        _.remove(newAnswer, a => !allItems.map(i => i.item_id).includes(a.item_id));
        return newAnswer;
    }
    return [];
};

// Custom hook for multi slider single picker question
const useMultiSliderSinglePickerQuestion = (template) => {
    const dispatch = useDispatch(); // Get dispatch function from redux
    const user = useSelector(state => state.user); // Get user from redux
    const questionAnswers = useSelector(state => state.questionAnswers); // Get question answers from redux
    const requiredError = useSelector(state => state.requiredError); // Get required error from redux
    const pageQuestionErrors = useSelector(state => state.pageQuestionErrors); // Get page question errors from redux
    const questionAdditionalItems = useSelector(state => state.questionAdditionalItems); // Get question additional items from redux
    const language = useSelector(state => state.language); // Get language from redux
    const questions = useSelector(state => state.questionsTemplate); // Get questions from redux
    const [answer, setAnswer] = useState([]); // State for answer
    const [answerSum, setAnswerSum] = useState(0); // State for answer sum
    const [additionalItems, setAdditionalItems] = useState([]); // State for additional items
    const [questionItems, setQuestionItems] = useState([]); // State for question items

    // Get the question input name
    const questionInputName = template.items_input 
        ? questions.find(q => q.element_id === template.items_input)?.question_text 
        : null;

    // Use effect to keep track of dependant question
    useEffect(() => {
        // keep track of dependant question
        if (template.items_input) {
            const inputItems = questionAnswers.find(qa => qa.questionId === template.items_input);
            if (inputItems && inputItems.answer && !_.isEqual(inputItems.answer, questionItems)) {
                setQuestionItems(inputItems.answer);
            } else if (!inputItems) {
                setQuestionItems([]);
            }
        } else {
            // normal question, items in template
            setAdditionalItems(questionAdditionalItems[template.element_id] ? questionAdditionalItems[template.element_id] : []);
        }
        const questionAnswer = questionAnswers.find(qa => qa.questionId === template.element_id);
        if (questionAnswer && !_.isEqual(questionAnswer.answer, answer)) {
            setAnswer(questionAnswer.answer);
        } else if (!questionAnswer) {
            setAnswer([]);
        }
    }, [template, questionAnswers]);

    // Use effect to update the question answer
    useEffect(() => {
        let newAnswer = filterAnswer(answer, questionItems);
        if (!_.isEqual(newAnswer, answer)) {
            setAnswer(newAnswer);
            dispatch(updateQuestionAnswer(template.element_id, newAnswer, user.token, questionAnswers));
        }
    }, [questionItems]);

    // Use effect to update the answer sum
    useEffect(() => {
        setAnswerSum(getAnswerSum(answer));

        const requiredError = allRequiredError();
        if (requiredError && !pageQuestionErrors.includes(template.element_id)) {
            dispatch(addPageQuestionError(template.element_id));
        } else if (!requiredError && pageQuestionErrors.includes(template.element_id)) {
            // all items answered, remove question from required error arr
            dispatch(removePageQuestionError(template.element_id));
        }
    }, [answer]);

    // Use effect to check barrier error
    useEffect(() => {
        console.log('answer sum: ', answerSum);
        if (template.items_calculation_sum) {
            const requiredError = allRequiredError();

            if (answerSum && template.items_calculation_barrier_value < answerSum) {
                // update question error if necessary
                if (!pageQuestionErrors.includes(template.element_id)) {
                    dispatch(addPageQuestionError(template.element_id));
                }
            } else if (!requiredError) {
                console.log('remove question from page question errors!');
                dispatch(removePageQuestionError(template.element_id));
            }
        }
    }, [answerSum]);

    // Function to handle slider change
    const handleSliderChange = (value, id, title) => {
        console.log(`slider changed! value: ${value}, slider id: ${id}, answer: ${answer}, title: ${title}`);
        let newAnswer = getNewQuestionAnswer(answer, value, id, title);
        setAnswer(newAnswer);
        dispatch(updateQuestionAnswer(template.element_id, newAnswer, user.token, questionAnswers));
    };

    // Function to add an item
    const addItem = () => {
        const itemId = uuid.v4();
        setAdditionalItems([...additionalItems, itemId]);
        dispatch(addAdditionalItem(itemId, template.element_id));
    };

    // Function to remove an item
    const removeItem = (itemId) => {
        const newItems = _.cloneDeep(additionalItems);
        const itemIndex = newItems.findIndex(i => i.item_id === itemId);
        if (itemIndex !== -1) {
            newItems.splice(itemIndex, 1);
            setAdditionalItems(newItems);
            dispatch(removeAdditionalItem(itemId, template.element_id));
        }
        let newAnswer = removeAnswer(answer, itemId);
        setAnswer(newAnswer);
        dispatch(updateQuestionAnswer(template.element_id, newAnswer, user.token, questionAnswers));
    };

    // Function to check if there is a barrier error
    const barrierError = () => {
        return !!(template.items_calculation_sum && answerSum && template.items_calculation_barrier_value < answerSum);
    };

    // Function to check if there is a required error
    const allRequiredError = () => {
        if (template.all_required) {
            // check number of items vs number of answers
            let numOfItems = template.items_input ? questionItems.length : template.items.length;
            numOfItems += additionalItems.length;

            if (answer.length < numOfItems) {
                return true;
            }
        }
        return false;
    };

    // Return the necessary values and functions
    return {
        questionInputName,
        answer,
        questionItems,
        additionalItems,
        answerSum,
        language,
        handleSliderChange,
        addItem,
        removeItem,
        barrierError,
        allRequiredError,
        requiredError,
    }
};

export default useMultiSliderSinglePickerQuestion;