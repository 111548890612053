import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Pagination from '@material-ui/lab/Pagination';
import makeStyles from "@material-ui/core/styles/makeStyles";

import './page-navigation.scss';
import {goToPage} from "../redux/actions";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function PageNavigation(props) {
    const {numberOfPages, currentPage} = props;
    const classes = useStyles();
    const [page, setPage] = useState(currentPage ? currentPage : 1);

    const dispatch = useDispatch();

    useEffect(() => {
        setPage(currentPage);
    }, [currentPage]);

    const handleChange = (event, value) => {
        if (value !== page) {
            console.log('go to page', value);
            dispatch(goToPage(value));
        }
    };

    return (
        <div className={`question-navigation ${classes.root}`}>
            <Pagination count={numberOfPages} page={page} onChange={handleChange}/>
        </div>
    )
}

export default PageNavigation;
