import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { updateRespondentsStatus } from '../redux/actions';

// Constants
const ID_VALUE_YES = 'ID_VALUE_YES';
const ID_VALUE_NO = 'ID_VALUE_NO';

const useIdentityConfirmationPage = (props) => {
    const { 
        userName, 
        tokenError, 
        companyLogo, 
        companyName, 
        confirmNameTitle, 
        confirmNameText, 
        confirmationNote, 
        t, 
        identityConfirmed } = props; // Get the variables and functions from props
    const dispatch = useDispatch(); // Get the dispatch function from the redux store
    const user = useSelector(state => state.user); // Get the user from the redux store
    const language = useSelector(state => state.language); // Get the language from the redux store
    const [value, setValue] = useState(null); // State variable for the radio button value
    const [identityNotConfirmed, setIdentityNotConfirmed] = useState(false); // State variable for identity confirmation
    

    // Function to handle the chekbox change
    const handleChange = (value) => {
        setValue(value ? ID_VALUE_YES : false); // If value is true, set the value to ID_VALUE_YES, otherwise set it to false
    };

    // Function to handle the next button click
    const handleNextClick = () => {
        console.log('clicked next, radio button value: ', value);
        if (value === ID_VALUE_NO) {
            setIdentityNotConfirmed(true);
        } else if (value === ID_VALUE_YES && identityConfirmed) {
            identityConfirmed();
            dispatch(updateRespondentsStatus(user.token, true));
        }
    };

    // Function to replace the placeholders in the text
    const replacePlaceholders = (text) => {
        return text
            .replace(`[Respondent's Name]`, userName)
            .replace(`[Company Name]`, companyName);
    };

    // Function to get the confirmation text
    const getConfirmationText = () => {
        const confirmationText = confirmationNote === false ? t('confirmNameText') : confirmNameText;
        return confirmationText ? replacePlaceholders(confirmationText) : '';
    }

    // Function to get the confirmation title
    const getConfirmationTitle = () => {
        const confirmationTitle = confirmationNote === false ? t('welcome') : confirmNameTitle;
        return confirmationTitle ? replacePlaceholders(confirmationTitle) : '';
    }

    // Return the variables and functions
    return {
        userName,
        tokenError,
        companyLogo,
        value,
        identityNotConfirmed,
        language,
        handleChange,
        handleNextClick,
        getConfirmationText,
        getConfirmationTitle
    };
};

export default useIdentityConfirmationPage;
