import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuestionAnswer } from "../../redux/actions";
import { colorTheme } from "../../assets/theme";
import _ from 'lodash';

// Custom hook to handle the text box question
const useTextBoxQuestion = (template, user) => {
    const dispatch = useDispatch(); // Get the dispatch function from the Redux store
    const questionAnswers = useSelector(state => state.questionAnswers); // Get the questionAnswers array from the Redux store
    const requiredError = useSelector(state => state.requiredError); // Get the requiredError object from the Redux store
    const isMobileScreen = useSelector(state => state.isMobileScreen); // Get the isMobileScreen boolean from the Redux store
    const [answer, setAnswer] = useState(null); // Create a state variable for the answer

    // Create a debounced function to update the question answer
    const delayedUpdate = useCallback(_.debounce(answer => {
        dispatch(updateQuestionAnswer(template.element_id, answer, user.token, questionAnswers));
    }, 300), []);

    // useEffect hook to set the answer state variable
    useEffect(() => {
        const questionAnswer = questionAnswers.find(qa => qa.questionId === template.element_id);
        setAnswer(questionAnswer ? questionAnswer.answer : null);
    }, [template, questionAnswers]);

    // Function to handle the textarea change
    const handleTextareaChange = (event) => {
        const value = event.target.value;
        console.log('textarea changed! value: ', value);
        setAnswer(value);
        delayedUpdate(value);
    };

    // Function to get the remaining characters color
    const getRemainingCharactersColor = () => {
        if (template.max_characters) {
            const remainingCharacters = template.max_characters - (answer ? answer.length : 0);
            if (remainingCharacters === 0) {
                return colorTheme.theme.text.red;
            } else if (remainingCharacters > template.max_characters / 2) {
                return colorTheme.theme.text.green;
            } else {
                return colorTheme.theme.text.orange;
            }
        }
        return 'inherit';
    };

    // Return the necessary variables and functions
    return {
        answer,
        isMobileScreen,
        requiredError,
        handleTextareaChange,
        getRemainingCharactersColor
    };
};

export default useTextBoxQuestion;
