import React from 'react';
import './question-container.scss';
import Typography from "@material-ui/core/Typography";
import useQuestionContainer from './../hooks/use-question-container';
import PageNavigation from "../question-layout/page-navigation";
import ProgressBar from "../question-layout/progress-bar";
import QuestionActions from "../question-layout/question-actions";
import TextBoxQuestion from "./text-box-question/text-box-question";
import SingleSliderSinglePickerQuestion from "./single-slider-single-picker-question/single-slider-single-picker-question";
import MultiSliderSinglePickerQuestion from "./multi-slider-single-picker-question/multi-slider-single-picker-question";
import SingleLineTextQuestion from "./single-line-text-question/single-line-text-question";
import SingleSelectQuestion from "./single-select-question/single-select-question";
import SearchChooseQuestion from "./search-choose-question/search-choose-question";
import MultiSelectQuestion from "./multi-select/multi-select-question";
import Section from "./section/section";
import PriorityListQuestion from "./priority-list-question/priority-list-question";
import UserSatisfactionQuestion from "./single-select-question/user-satisfaction-question";
import MatrixSingleSelectQuestion from "./matrix-single-select/matrix-single-select-question";
import MatrixMultiSelectQuestion from "./matrix-multiple-select/matrix-multi-select-question";
import RestartDialog from "../question-layout/restart-dialog";
import { getStyle } from "./styleUtil";
import { colorTheme } from '../assets/theme';
import { Box } from '@material-ui/core';
import {
    TEXT_BOX,
    SINGLE_SLIDER_SINGLE_PICKER,
    SECTION,
    MULTI_SLIDER_SINGLE_PICKER,
    SINGLE_LINE_TEXT,
    SINGLE_SELECT,
    MULTI_SELECT,
    PRIORITY_LIST,
    SEARCH_CHOOSE,
    USER_SATISFACTION,
    MATRIX_SINGLE_SELECT,
    MATRIX_MULTI_SELECT,
} from "../assets/constants";

// Function to render question based on question type
const renderQuestion = (question) => {
    switch (question.element_type) {
        case SINGLE_SLIDER_SINGLE_PICKER:
            return <SingleSliderSinglePickerQuestion template={question} />;
        case MULTI_SLIDER_SINGLE_PICKER:
            return <MultiSliderSinglePickerQuestion template={question} />;
        case TEXT_BOX:
            return <TextBoxQuestion template={question} />;
        case SINGLE_LINE_TEXT:
            return <SingleLineTextQuestion template={question} />;
        case SINGLE_SELECT:
            return <SingleSelectQuestion template={question} />;
        case SEARCH_CHOOSE:
            return <SearchChooseQuestion template={question} />;
        case MULTI_SELECT:
            return <MultiSelectQuestion template={question} />;
        case SECTION:
            return <Section template={question} />;
        case PRIORITY_LIST:
            return <PriorityListQuestion template={question} />;
        case USER_SATISFACTION:
            return <UserSatisfactionQuestion template={question} />;
        case MATRIX_SINGLE_SELECT:
            return <MatrixSingleSelectQuestion template={question} />;
        case MATRIX_MULTI_SELECT:
            return <MatrixMultiSelectQuestion template={question} />;
        default:
            return <div>unknown question type!</div>;
    }
};

// QuestionContainer component
const QuestionContainer = (props) => {
    const { template } = props; // Destructuring template from props
    const {
        pages,
        currentPage,
        language,
        currentPageIndex,
        questionNumeration,
        jumpToQuestion,
        getQuestionOrder,
    } = useQuestionContainer(template, props); // Destructuring values from custom hook

    return (
        <>
            {props.progressBar && <ProgressBar />}
            <Box className='question-container'>
                <Box className='questions'>
                    {currentPage &&
                        currentPage.questionIds.map((questionId) => {
                            const question = template.find(q => q.element_id === questionId);
                            return question ? (
                                <Box className='question' key={questionId}>
                                    {question.element_type !== SECTION && (
                                        <Box className='question-title' style={getStyle('question-title', language)}>
                                            <Typography className='main-title' style={{ color: colorTheme.theme.text.darkGrey, fontSize: '20px', fontWeight: '500', lineHeight: '24px' }}>
                                                <span
                                                    className={`question-number ${question.element_order < 10 ? 'small' : 'large'}`}
                                                    style={{ fontWeight: '700', ...getStyle('question-number-span', language) }}>
                                                    {questionNumeration && getQuestionOrder(question)}
                                                </span>
                                                {question.question_text}
                                            </Typography>
                                            {question.question_explanation_string && (
                                                <Typography className='subtitle' style={{ ...getStyle('subtitle', language), color: colorTheme.theme.text.lightGrey }}>
                                                    {question.question_explanation_string}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                    {renderQuestion(question)}
                                </Box>
                            ) : '';
                        })}
                </Box>
            </Box>
            <QuestionActions
                    hasPrevious={currentPageIndex && currentPageIndex > 1}
                    hasNext={currentPageIndex && pages && currentPageIndex < pages.length}
                />
            <RestartDialog jumpToQuestion={jumpToQuestion} />
        </>
    );
};

export default QuestionContainer;
