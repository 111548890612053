import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {updateQuestionAnswer} from "../../redux/actions";

// Custom hook for single slider single picker question
const useSingleSliderSinglePickerQuestion = (template) => {
    const dispatch = useDispatch(); // Get dispatch function from redux
    const user = useSelector(state => state.user); // Get user from redux
    const questionAnswers = useSelector(state => state.questionAnswers); // Get question answers from redux
    const requiredError = useSelector(state => state.requiredError); // Get required error from redux
    const [answer, setAnswer] = useState(null); // State for answer

    // useEffect hook to set answer from questionAnswers
    useEffect(() => {
        const questionAnswer = questionAnswers.find(qa => qa.questionId === template.element_id);
        setAnswer(questionAnswer ? questionAnswer.answer : null);
    }, [template, questionAnswers]);

    const sliderId = `slider-${template.element_id}`; // Set slider id from template element_id

    // Function to handle slider change
    const handleSliderChange = (value, id) => {
        console.log(`slider changed! value: ${value}, slider id: ${id}`);
        setAnswer(value);
        dispatch(updateQuestionAnswer(template.element_id, value, user.token, questionAnswers));
    };

    // Return necessary values and functions
    return { 
        sliderId, 
        answer, 
        requiredError,
        handleSliderChange
     };
};

export default useSingleSliderSinglePickerQuestion;