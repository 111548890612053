import React, { useEffect, useState } from 'react';
import './slider-component.scss';
import { useTranslation } from "react-i18next";
import { Box } from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Slider from '@material-ui/core/Slider';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import AlertComponent from "../input-components/alert-component";

// Make styles for slider component
const useStyles = makeStyles(() => ({
    markLabel: {
        fontSize: '14px',
        lineHeight: '20px',
        opacity: '0.8'
    },
    privateValueLabel: {
        left: 'calc(-50%)',
        '& > span': {
            width: '26px',
            height: '26px',

            '& > span': {
                fontSize: '14px',
            }
        }
    },
    markActive: {
        backgroundColor: 'transparent'
    }
}));

// Function to create marks for slider
const createMarks = (min, max, step) => {
    let marks = [];
    let counter = min;
    while (counter < max) {
        marks.push({
            value: counter,
            label: counter === min ? counter.toString() : null
        });
        counter += step
    }

    // makes sure max is included in slider
    marks.push({
        value: max,
        label: max
    });

    return marks;
};

// Slider component
function SliderComponent(props) {
    const { id, minText, maxText, min, max, step, title, value, editable, required, customLabel, defaultValue } = props; // Get the props
    const { t } = useTranslation(); // Translation function
    const classes = useStyles(); // Get the styles
    const [sliderValue, setSliderValue] = useState(value); // State for slider value
    const [marks, setMarks] = useState([]); // State for marks
    const [sliderTitle, setSliderTitle] = useState(title); // State for slider title
    const [editMode, setEditMode] = useState(editable ? true : false); // State for edit mode

    // Create marks for slider
    useEffect(() => {
        setMarks(createMarks(min, max, step));
    }, [min, max, step]);

    // Update the slider value and title
    useEffect(() => {
        setSliderValue(value);
        setSliderTitle(title);
    }, [id, value, title]);

    // Handle the title change
    const handleTitleChange = (event) => {
        setSliderTitle(event.target.value);
    };

    // Handle the title confirm
    const handleTitleConfirm = () => {
        setEditMode(false);
        props.onChange(value, props.id, sliderTitle);
    };

    // Handle the edit mode open
    const handleEditModeOpen = () => {
        setEditMode(true);
    };

    // Handle the slider change
    const handleSliderChange = (event, value) => {
        if (value !== sliderValue) {
            setSliderValue(value);
        }
    };

    // Handle the slider change committed
    const handleSliderChangeCommitted = (event, value, text) => {
        console.log('committed value: ', value);
        props.onChange(value, props.id, sliderTitle);
    };

    // Handle the remove slider
    const handleRemoveSlider = () => {
        console.log('clicked remove slider, slider id: ', id);
        props.onRemoveItem(id);
    };

    // Function to render the slider title
    const renderSliderTitle = () => {
        if (typeof sliderTitle === 'string') {
            return sliderTitle;
        } else if (typeof sliderTitle === 'object' && sliderTitle !== null) {
            return Object.values(sliderTitle).map((value, index) => (
                <span key={index}>{value}</span>
            ));
        } else {
            return null; // handle other cases if necessary
        }
    };

    return (
        <>
            <Box className={`title-container ${required ? 'error' : ''}`}>
                {
                    editMode ?
                        <Box className='title'>
                            <TextField id="discrete-slider" className='slider-title-input' label={customLabel ? customLabel : t("itemName")} value={sliderTitle} onChange={handleTitleChange} />
                            <CheckIcon className='confirm-icon action-icon edit-mode' onClick={handleTitleConfirm} />
                            <CancelIcon onClick={handleRemoveSlider} className='delete-icon action-icon edit-mode' />
                        </Box> :
                        <Box className='title'>
                            <Typography id="discrete-slider" className='slider-title' gutterBottom>
                                {renderSliderTitle()}
                            </Typography>
                            {
                                editable &&
                                <Box className='action-icons'>
                                    <CreateIcon className='edit-icon action-icon' onClick={handleEditModeOpen} />
                                    <CancelIcon onClick={handleRemoveSlider} className='delete-icon action-icon' />
                                </Box>
                            }
                        </Box>
                }
                {required && <AlertComponent text={t("required")} type='error' />}
            </Box>

            <Box className='slider' style={{ width: '100%', height: '28px', position: 'relative' }}>
                {
                    (minText || maxText) &&
                    <Box className='min-max-text'>
                        <span>{minText}</span>
                        <span>{maxText}</span>
                    </Box>
                }

                <Slider
                    classes={{
                        markLabel: classes.markLabel,
                        valueLabel: classes.privateValueLabel,
                        markActive: classes.markActive
                    }}
                    color='secondary'
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay={sliderValue !== null ? 'on' : 'auto'}
                    marks={marks}
                    defaultValue={defaultValue}
                    value={sliderValue !== null ? sliderValue : defaultValue > 1 ? defaultValue : 1}
                    step={step}
                    min={min}
                    max={max}
                    onChange={handleSliderChange}
                    onChangeCommitted={handleSliderChangeCommitted}
                />
            </Box>
        </>
    );
}

export default SliderComponent;
