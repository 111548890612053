import React from 'react';
import './multi-select-question.scss';
import useMultiSelectQuestion from './../../hooks/questions/use-multi-select-question';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AlertComponent from "../../input-components/alert-component";
import _ from 'lodash';
import { ReactComponent as CheckIcon } from '../../assets/images/checked-2-icon.svg';
import { ReactComponent as CheckedIcon } from '../../assets/images/checked-icon.svg';
import { ReactComponent as UnCheckedIcon } from '../../assets/images/unchecked-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/trash-icon.svg';
import { colorTheme } from '../../assets/theme';
import { useTranslation } from "react-i18next";
import { getStyle } from "../styleUtil"
import { Box, Typography } from '@material-ui/core';
import TextButton from '../../components/buttons/text-button';
import {
    SINGLE_SELECT_SUBTYPE_IMAGE,
    SINGLE_SELECT_SUBTYPE_TEXT,
    SINGLE_SELECT_SUBTYPE_TEXT_BUTTON
} from "../../assets/constants";

// Styles for the component
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '20px',
        // '& > *': {
        //     margin: theme.spacing(0.5),
        // },
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            padding: '0px',
            fontSize: '15px',
            lineHeight: '20px',
            '& input': {
                padding: '8px 12px',
                color: colorTheme.theme.text.mediumGrey,
                height: 'initial',
            },
            '& fieldset': {
                borderColor: colorTheme.theme.lines.lightGrey,
                transition: 'border-color 0.3s',
            },
            '&:hover fieldset': {
                borderColor: colorTheme.theme.text.mediumGrey,
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--colors-highlight)',
                borderWidth: '1px',
            },
        },
        '& .MuiInputBase-input::placeholder': {
            color: colorTheme.theme.text.lightGrey,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
            transform: 'translate(12px, 8px) scale(1)', // Placeholder stays in place
            fontSize: '15px',
            lineHeight: '20px',
            textTransform: 'none',

            '&.Mui-focused': {
                display: 'none',
            }
        },
    },
    textFieldChip: {
        '&.selected': {
            '& .MuiOutlinedInput-root': {
                backgroundColor: 'var(--colors-highlight)',

                '& input': {
                    fontWeight: '500',
                    color: colorTheme.theme.text.darkGrey
                },
                '& fieldset': {
                    borderColor: 'var(--colors-highlight)',
                },
                '&:hover fieldset': {
                    borderColor: 'var(--colors-highlight)',
                },
            },
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '30px',

            '& input': {
                padding: '10px 24px',
                textAlign: 'center',
            }
        },

        '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
            transform: 'translate(-50%, 10px) scale(1)',
            left: '50%',
        }
    },
    formControl: {
        '& .MuiFormGroup-root': {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',

            '& .MuiFormControlLabel-root': {
                margin: '0px',
                display: 'flex',
                alignItems: 'center',
                gap: '12px',

                '& .MuiIconButton-root': {
                    padding: '0px',
                },

                '& .MuiFormControlLabel-label': {
                    fontSize: '15px',
                    lineHeight: '19px',
                    color: colorTheme.theme.text.mediumGrey,
                }
            },
        },
    },
    chip: {
        borderRadius: '30px',
        padding: '10px 24px',
        height: 'initial',
        transition: 'box-shadow 0.3s !important',
        border: '1px solid var(--colors-highlight)',
        backgroundColor: 'var(--colors-highlight)',


        '& .MuiChip-label': {
            padding: '0px',
            fontSize: '15px',
            lineHeight: '19px',
            fontWeight: '400',
            textTransform: 'none',
            fontWeight: '600',
            color: colorTheme.theme.text.darkGrey
        },

        '&.MuiChip-outlined': {
            backgroundColor: 'transparent',
            color: colorTheme.theme.text.darkGrey,
            borderColor: 'var(--colors-highlight)',

            '& .MuiChip-label': {
                fontWeight: '500',
            },

            '&:hover': {
                backgroundColor: 'transparent',
            },
        },

        '&:focus': {
            backgroundColor: 'var(--colors-highlight)',
        },

        '&:hover': {
            boxShadow: '0px 2px 4px 0px #7B7D898C',
        },
    }
}));

// Check if the answer is checked
const isChecked = (answer, itemId) => {
    if (answer && answer.length) {
        return answer.map(a => a.item_id).includes(itemId);
    }
    return false;
};

// MultiSelectQuestion component - todo add error handling for min and max answers
function MultiSelectQuestion(props) {
    const { template } = props; // Get the template from the props
    const { t } = useTranslation(); // Translation
    const classes = useStyles(); // Styles

    const {
        customItems,
        answer,
        requiredError,
        minError,
        language,
        handleSelectChange,
        handleTextFieldChange,
        handleItemAdd,
        handleItemRemove
    } = useMultiSelectQuestion(template); // Get necessary variables and functions from the hook

    const renderCustomItems = () => {
        return customItems ?
            customItems.map((item, index) => {
                return (
                    <Box className='custom-item' key={index} style={{ display: 'flex', gap: '20px' }}>
                        <FormControlLabel
                            value={item}
                            control={
                                <Checkbox
                                    icon={<UnCheckedIcon />}
                                    checkedIcon={<CheckedIcon className={isChecked(answer, item.item_id) ? 'checked-icon' : ''} />}
                                    checked={isChecked(answer, item.item_id)}
                                    onChange={e => handleSelectChange(item.item_id, item.item_text, e.target.checked)}
                                    name={item.item_text}
                                />
                            }
                            label={
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    className={classes.textField}
                                    value={item.item_text}
                                    inputProps={{ maxLength: 100 }}
                                    InputLabelProps={{ shrink: false }}
                                    onChange={e => handleTextFieldChange(e, item.item_id)}
                                    label={item.item_text ? null : (template.item_placeholder_text ? template.item_placeholder_text : t("other"))}
                                    onClick={() => {
                                        if (!isChecked(answer, item.item_id)) {
                                            handleSelectChange(item.item_id, item.item_text, true);
                                        }
                                    }}
                                />
                            }
                        />
                        {
                            customItems.length > 0 && !(template.items_other_allowed == 1) &&
                            <DeleteIcon onClick={() => handleItemRemove(item.item_id)} className='remove-icon' />
                        }
                    </Box>
                )
            }) : ('');
    };

    return (
        <Box className='multi-select-question-container'>
            {
                template &&
                <Box>
                    {
                        template.style_type === SINGLE_SELECT_SUBTYPE_TEXT &&
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                {
                                    template.items.map((item, index) => {
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        icon={<UnCheckedIcon />}
                                                        checkedIcon={<CheckedIcon className={isChecked(answer, item.item_id) ? 'checked-icon' : ''} />}
                                                        onChange={e => handleSelectChange(item.item_id, item.item_text, e.target.checked)}
                                                        name={item.item_text}
                                                    />
                                                }
                                                label={item.item_text}
                                            />
                                        )
                                    })
                                }
                                {
                                    renderCustomItems()
                                }
                            </FormGroup>
                        </FormControl>
                    }
                    {
                        template.style_type === SINGLE_SELECT_SUBTYPE_TEXT_BUTTON &&
                        <Box className={`${classes.root} text-buttons`}>
                            {
                                template.items.map((item, index) => {
                                    return (
                                        <Chip
                                            key={index}
                                            className='text-button'
                                            classes={{ root: classes.chip }}
                                            label={item.item_text}
                                            value={item.item_text}
                                            variant={isChecked(answer, item.item_id) ? 'default' : 'outlined'}
                                            onClick={() => handleSelectChange(item.item_id, item.item_text, !isChecked(answer, item.item_id))}
                                        />
                                    )
                                })
                            }
                            {
                                customItems &&
                                customItems.map((item, index) => {
                                    return (
                                        <Box key={index} className='custom-item'>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                value={item.item_text}
                                                InputLabelProps={{ shrink: false }}
                                                className={classes.textField + ' ' + classes.textFieldChip + (isChecked(answer, item.item_id) && item.item_text != "" ? ' selected' : '')}
                                                inputProps={{ maxLength: 100 }}
                                                onChange={e => handleTextFieldChange(e, item.item_id)}
                                                label={item.item_text ? null : (template.item_placeholder_text ? template.item_placeholder_text : t("other"))}
                                                onClick={() => handleSelectChange(item.item_id, item.item_text, !isChecked(answer, item.item_id))}
                                            />
                                            {
                                                <DeleteIcon onClick={() => handleItemRemove(item.item_id)} className='remove-icon' />
                                            }
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    }
                    {
                        template.style_type === SINGLE_SELECT_SUBTYPE_IMAGE &&
                        <Box className='images' style={{ paddingTop: '16px', display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                            {
                                template.items.map((item, index) => {
                                    return item.item_extra_url ?
                                        (
                                            <Box key={index} className='image-option expandable'>
                                                <img src={item.item_url} alt='extra-info' className='image' />
                                                <Accordion className='extra-info-container'>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-label="Expand"
                                                        aria-controls="additional-actions1-content"
                                                        id="additional-actions1-header"
                                                        className='extra-info-header'
                                                    >
                                                        <FormControlLabel
                                                            aria-label="Acknowledge"
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                            }}
                                                            onFocus={(event) => event.stopPropagation()}
                                                            control={
                                                                <Checkbox
                                                                    onChange={() => {
                                                                        handleSelectChange(item.item_id, item.item_text, !isChecked(answer, item.item_id))
                                                                    }}
                                                                    checked={isChecked(answer, item.item_id)}
                                                                />
                                                            }
                                                            label={<span className='label'>{item.item_text}</span>}
                                                        />
                                                    </AccordionSummary>
                                                    <AccordionDetails className='extra-info-content-container'>
                                                        <img src={item.item_extra_url} alt='extra-info' className='extra-info-content' />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                        ) :
                                        (
                                            <Box key={index} className='image-option' onClick={() => handleSelectChange(item.item_id, item.item_text, !isChecked(answer, item.item_id))} style={{ borderRadus: '4px', border: '1px solid ' + (answer && isChecked(answer, item.item_id) ? 'var(--colors-highlight)' : colorTheme.theme.lines.lightGrey), display: 'flex', flexDirection: 'column', width: '210px', borderRadius: '4px' }}>
                                                <Box className='image' style={{ borderBottom: '1px solid ' + colorTheme.theme.lines.lightGrey, position: 'relative', width: '100%', height: '160px', cursor: 'pointer', borderRadius: '4px 4px 0 0' }}>
                                                    <img src={item.item_url} alt='extra-info' className='image' style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '3px 3px 0px 0px' }} />
                                                    {
                                                        answer && isChecked(answer, item.item_id) &&
                                                        <CheckIcon className='check-icon' />
                                                    }
                                                </Box>
                                                <span className='image-text' style={{ padding: '10px', textAlign: 'center', fontSize: '15px', flexGrow: '1' }}>{item.item_text}</span>
                                            </Box>
                                        );
                                })
                            }
                        </Box>
                    }
                    {
                        template.style_type === SINGLE_SELECT_SUBTYPE_IMAGE &&
                        renderCustomItems()
                    }
                    {
                        template.item_other_allowed && template.items_max_number && customItems.length < template.items_max_number &&
                        <Box className='button-and-info' style={{ ...getStyle('button-and-info', language), marginTop: '16px' }}>
                            <TextButton onClick={() => handleItemAdd()} title={template.item_add_text ? template.item_add_text : t("addOtherAnswer")} />
                        </Box>
                    }
                    {
                        template && answer && template.items_max_number &&
                        <Box style={{ ...getStyle('items-left', language), marginTop: '20px' }}>
                            <Typography className='items-left' style={{ color: colorTheme.theme.text.mediumGrey, fontSize: '14px', lineHeight: '20px' }}>{t("itemsLeft")}: <span style={{ color: (template.items_max_number - answer.length) > 0 ? colorTheme.theme.text.green : colorTheme.theme.text.red, fontWeight: '600' }}>{template.items_max_number - answer.length}</span></Typography>
                        </Box>
                    }
                    {
                        minError &&
                        <AlertComponent text={`${t("answerInputInfoPt1")} ${template.items_min_number} ${t("answerInputInfoPt2Select")}`} type='info' />
                    }
                    {requiredError && template.required && _.isEmpty(answer) &&
                        <AlertComponent text={`**${t("questionRequired")}** ${t("provideAnswer")}`} type='error' />}
                </Box>
            }
        </Box>
    );
}

export default MultiSelectQuestion;