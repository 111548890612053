import React, { useEffect } from 'react';
import './search-choose-question.scss';
import useSearchChooseQuestion from '../../hooks/questions/use-search-choose-question';
import Autocomplete from '@material-ui/lab/Autocomplete';
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import AlertComponent from "../../input-components/alert-component";
import TextButton from "../../components/buttons/text-button";
import AddAnswerDialog from '../../components/dialogs/add-answer';
import { useTranslation } from "react-i18next";
import { ITEMS_SOURCE_TYPE_LIST, ITEMS_SOURCE_TYPE_QUESTION } from "../../assets/constants";
import { getStyle } from "../styleUtil";
import { colorTheme } from '../../assets/theme';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as XIcon } from '../../assets/images/icons/x-icon.svg';

const useStyles = makeStyles((theme) => ({
    chips: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& .MuiChip-root': {
            padding: '4px 12px',
            borderRadius: '30px',
            display: 'flex',
            gap: '6px',
            '& .MuiChip-label': {
                padding: '0px',
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '24px',
            },
            '& svg': {
                margin: '0px',
                width: 'initial',
                height: 'initial',
                '& rect': {
                    fill: 'var(--colors-highlight)',
                    transition: 'fill 0.3s ease-in-out',
                },
                '& path': {
                    fill: 'var(--colors-fontLight)',
                    transition: 'fill 0.3s ease-in-out',
                },
                '&:hover': {
                    '& rect': {
                        fill: 'var(--colors-fontLight)'
                    },
                    '& path': {
                        fill: 'var(--colors-highlight)'
                    },
                },
            },
        },
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    autocomplete: {
        '& .MuiAutocomplete-inputRoot': {
            padding: '0px !important',
            '& .MuiAutocomplete-input': {
                padding: '14px 28px 14px 12px !important',
                width: '100%',
            },

            '& .MuiAutocomplete-clearIndicator': {
                display: 'none',
            },
        },
        '& fieldset': {
            borderColor: colorTheme.theme.lines.lightGrey,
            transition: 'border-color 0.3s',
        },
        '&:hover fieldset': {
            borderColor: colorTheme.theme.text.mediumGrey,
        },
        '&.Mui-focused fieldset': {
            borderColor: 'var(--colors-main) !important',
            borderWidth: '1px !important',
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 16px) scale(1)',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
        },
    },
    listItem: {
        '& p': {
            margin: '0px',
            fontSize: '14px',
            lineHeight: '16px',
            color: colorTheme.theme.text.lightGrey,
            '&:first-child': {
                color: colorTheme.theme.text.mediumGrey,
                lineHeight: '21px',
            },
        }
    }
}));

function SearchChooseQuestion(props) {
    const { template } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        answer,
        questionItems,
        newItem,
        openDialog,
        inputValue,
        autocompleteItems,
        requiredError,
        isMobileScreen,
        language,
        handleItemRemove,
        handleItemAdd,
        handleItemChange,
        handleNewItemAdd,
        onInputChange,
        handleClickOpenDialog,
        handleDialogClose,
        canSubmit,
        isInErrorState,
        itemsAddedByButton,
        minError
    } = useSearchChooseQuestion(template);

    const itemsLeftToAddByButton = Math.max(template.max_number_added_answers - itemsAddedByButton, 0);
    const maxItemsLeftToAdd = template.validation_max ? Math.max(template.items_max_number - answer.length, 0) : Math.max(template.max_number_added_answers - answer.length, 0);
    const minItemsRequired = template.validation_min ? template.items_min_number : 0;

    const getOptionLabel = (option) => {
        // Check if template.items_name_field is defined and is an array
        if (template.items_name_field && Array.isArray(template.items_name_field)) {
            // Use only the fields listed in template.items_name_field for the label
            return template.items_name_field.map(field => option[field] ? `${option[field]} ` : '').join('');
        } else if (template.items_name_field) {
            // If template.items_name_field is not an array but is defined, treat it as a single field
            return option[template.items_name_field] ? `${option[template.items_name_field]} ` : '';
        }
        // Fallback to using template.item_fields if template.items_name_field is not defined or empty
        return template.item_fields.map(field => option[field] ? `${option[field]} ` : '').join('');
    };

    return (
        <Box className='search-choose-question-container' style={getStyle('search-choose-question-container', language)}>
            { // items in template
                template && template.items && template.item_fields && template.items_source_type === ITEMS_SOURCE_TYPE_LIST &&
                <Autocomplete
                    value={null}
                    className={`${classes.root} ${classes.autocomplete} autocomplete`}
                    options={autocompleteItems.filter(item => answer.findIndex(a => a.item_id === item.item_id) === -1)}
                    getOptionLabel={getOptionLabel}
                    renderInput={(params) => <TextField {...params} label={t("search")} variant="outlined" />}
                    inputValue={inputValue}
                    disabled={maxItemsLeftToAdd <= 0}
                    onInputChange={onInputChange}
                    onChange={handleItemAdd}
                    renderOption={(option) => (
                        <Box className={classes.listItem}>
                            {template.item_fields.map((field, index) => option[field] ? <p key={index}>{option[field]}</p> : null)}
                        </Box>
                    )}
                />
            }
            { // items in list or answer from another question
                template && questionItems && template.item_fields && template.items_source_type === ITEMS_SOURCE_TYPE_QUESTION &&
                <Autocomplete
                    value={null}
                    className={`${classes.root} autocomplete`}
                    options={questionItems.filter(item => answer.findIndex(a => a.item_id === item.item_id) === -1)}
                    getOptionLabel={getOptionLabel}
                    renderInput={(params) => <TextField {...params} label={t("search")} variant="outlined" />}
                    inputValue={inputValue}
                    disabled={maxItemsLeftToAdd <= 0}
                    onInputChange={onInputChange}
                    onChange={handleItemAdd}
                />
            }
            {
                template && template.item_other_allowed && itemsLeftToAddByButton > 0 && maxItemsLeftToAdd > 0 &&
                <Box className='button-and-info' style={{ ...getStyle('button-and-info', language), marginTop: '16px' }}>
                    <TextButton onClick={() => handleClickOpenDialog()} title={template.item_add_text ? template.item_add_text : t("addOtherAnswer")} />
                </Box>
            }

            <Box style={{ ...getStyle('items-left', language), marginTop: '16px' }}>
                {template && answer && template.item_other_allowed &&
                    <Typography className='items-left' style={{ color: colorTheme.theme.text.mediumGrey, fontSize: '14px', lineHeight: '20px' }}>
                        {t("itemsLeftToAdd")}: <span style={{ color: itemsLeftToAddByButton > 0 ? colorTheme.theme.text.green : colorTheme.theme.text.red, fontWeight: '600' }}>{itemsLeftToAddByButton}</span>
                    </Typography>
                }
                {template && answer && template.validation_min &&
                    <><Typography className='items-min-required' style={{ color: colorTheme.theme.text.mediumGrey, fontSize: '14px', lineHeight: '20px' }}>
                        {t("minItemsRequired")}: <span style={{ color: answer.length >= minItemsRequired ? colorTheme.theme.text.green : colorTheme.theme.text.red, fontWeight: '600' }}>{minItemsRequired}</span>
                    </Typography></>
                }
                {template && answer && template.validation_min &&
                    <><Typography className='items-left' style={{ color: colorTheme.theme.text.mediumGrey, fontSize: '14px', lineHeight: '20px' }}>
                        {t("maxItemsLeft")}: <span style={{ color: maxItemsLeftToAdd > 0 ? colorTheme.theme.text.green : colorTheme.theme.text.red, fontWeight: '600' }}>{template.items_max_number}</span>
                    </Typography></>
                }
            </Box>
            {
                answer.length > 0 &&
                <Box className={`${classes.chips} text-buttons`} style={{ display: 'flex', gap: '12px', marginTop: '20px' }}>
                    {
                        answer && answer.map((item, index) => {
                            const chipLabel = item[template.item_fields[0]];
                            return (
                                <Chip
                                    key={index}
                                    className='text-button'
                                    label={chipLabel}
                                    value={item}
                                    deleteIcon={<XIcon />}
                                    variant={'default'}
                                    onDelete={() => handleItemRemove(item.item_id)}
                                />
                            )
                        })
                    }
                </Box>
            }
            {
                minError &&
                <AlertComponent text={`${t("answerInputInfoPt1")} ${template.items_min_number} ${t("answerInputInfoPt2Select")}`} type='info' />
            }
            {
                requiredError && template.required && !answer.length &&
                <AlertComponent text={t("required")} type={'error'} />
            }
            <AddAnswerDialog
                open={openDialog}
                handleClose={handleDialogClose}
                template={template}
                newItem={newItem}
                handleItemChange={handleItemChange}
                handleNewItemAdd={handleNewItemAdd}
                isInErrorState={isInErrorState}
                canSubmit={canSubmit}
            />
            <hr style={{ border: 'none', borderBottom: '1px solid ' + colorTheme.theme.lines.lightGrey, margin: '20px 0px 0px' }} />
        </Box>
    );
}

export default SearchChooseQuestion;
