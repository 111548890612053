import React from 'react';
import { Box, Typography, IconButton } from "@material-ui/core";
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close-icon.svg';

// HeaderDialog component for dialog
const HeaderDialog = ({ title, handleClose }) => {
    return (
        <Box style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #E0E0E0',
            padding: '16px 16px 12px 16px',
        }}>
            <Box style={{ display: 'flex', alignItems: 'center', pl: '8px', gap: 1 }}>
                <Typography style={{ flexShrink: 0, fontSize: '18px', lineHeight: '24px' }}>{title}</Typography>
            </Box>
            <IconButton onClick={handleClose} size="small">
                <CloseIcon />
            </IconButton>
        </Box>
    );
};

export default HeaderDialog;