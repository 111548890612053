import React from "react"
import ContentLoader from "react-content-loader"

const Loader = (props) => {
    const width = props.mobileScreen ? 300 : 550;

    return (
        <ContentLoader
            speed={2}
            width={width}
            height={550}
            viewBox={`0 0 ${width} 500`}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <circle cx="10" cy="26" r="8"/>
            <rect x="30" y="15" rx="5" ry="5" width={props.mobileScreen ? "270" : "550"} height="22"/>
            <circle cx="10" cy="275" r="8"/>
            <rect x="30" y="260" rx="5" ry="5" width={props.mobileScreen ? "270" : "550"} height="22"/>
            <rect x="30" y="61" rx="8" ry="8" width={props.mobileScreen ? "250" : "500"} height="57"/>
            <rect x="30" y="136" rx="8" ry="8" width={props.mobileScreen ? "250" : "500"} height="57"/>
            <rect x="30" y="305" rx="8" ry="8" width={props.mobileScreen ? "250" : "500"} height="57"/>
            <rect x="30" y="387" rx="8" ry="8" width={props.mobileScreen ? "250" : "500"} height="57"/>
            <rect x={props.mobileScreen ? "50" : "150"} y="460" rx="8" ry="8" width="110" height="32"/>
            <rect x={props.mobileScreen ? "170" : "270"} y="460" rx="8" ry="8" width="110" height="32"/>
        </ContentLoader>
    )
};

export default Loader;

