import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from 'lodash';
import TextField from "@material-ui/core/TextField";
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Button from "@material-ui/core/Button";
import pinRightGray from '../../assets/images/pin-right-gray.svg';
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import ForwardIcon from '@material-ui/icons/Forward';
import Fab from "@material-ui/core/Fab";

import './priority-list-question.scss';
import {updateQuestionAnswer} from "../../redux/actions";
import AlertComponent from "../../input-components/alert-component";
import dragIcon from "../../assets/images/drag-handle.svg";
import {getStyle} from "../styleUtil"

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // update item_order
    result.forEach((item, index) => {
        item.item_order = index + 1;
    });

    return result;
};

const addCustomItem = (customItem, items) => {
    let newItems = _.cloneDeep(items);
    newItems.push(customItem);
    return newItems;
};

const removeCustomItem = (items) => {
    let newItems = _.cloneDeep(items);
    _.remove(newItems, i => i.custom_item);
    return newItems;
};

const updateCustomItemTitle = (items, title) => {
    const customItemIndex = items.findIndex(i => i.custom_item);
    if (customItemIndex !== -1) {
        let newItems = _.cloneDeep(items);
        newItems[customItemIndex].item_text = title;
        return newItems;
    }
    return items;
};

// todo if requirements switch to allow multiple custom items to be added,
// we must switch the login in here to additionalItems in redux

function PriorityListQuestion(props) {
    const { template } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const questionAnswers = useSelector(state => state.questionAnswers);
    const requiredError = useSelector(state => state.requiredError);
    const language = useSelector(state => state.language);
    const user = useSelector(state => state.user);
    const [items, setItems] = useState([]);
    const [customItemTitle, setCustomItemTitle] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [canDragUp, setCanDragUp] = useState(false);
    const [canDragDown, setCanDragDown] = useState(false);

    useEffect(() => {
        const questionAnswer = questionAnswers.find(qa => qa.questionId === template.element_id);
        setItems(questionAnswer ? questionAnswer.answer : template.items);

        if (questionAnswer) {
            const customItem = questionAnswer.answer.find(item => item.custom_item);
            setCustomItemTitle(customItem ? customItem.item_text : '');
        }
    }, [template, questionAnswers]);

    const onDragEnd = (result) => {
        // dropped outside the list
        setCanDragDown(false);
        setCanDragUp(false);
        if (!result.destination) {
            return;
        }

        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(newItems);
        dispatch(updateQuestionAnswer(template.element_id, newItems, user.token, questionAnswers));
    };

    const handleTitleChange = (event) => {
        setCustomItemTitle(event.target.value);
    };

    const handleTitleConfirm = () => {
        setEditMode(false);

        const newItems = updateCustomItemTitle(items, customItemTitle);
        setItems(newItems);
        dispatch(updateQuestionAnswer(template.element_id, newItems, user.token, questionAnswers));
    };

    const handleEditModeOpen = () => {
        setEditMode(true);
    };

    const handleRemoveCustomItem = () => {
        const newItems = removeCustomItem(items);
        setItems(newItems);
        dispatch(updateQuestionAnswer(template.element_id, newItems, user.token, questionAnswers));
    };

    const addItem = () => {
        const newItem = {
            item_id: 'custom_item',
            item_text: '',
            item_order: items.length + 1, // order starts from 1
            custom_item: true
        };
        setEditMode(true);

        const newItems = addCustomItem(newItem, items);
        setItems(newItems);
    };

    const hasAnswer = () => {
        return questionAnswers.findIndex(qa => qa.questionId === template.element_id) !== -1;
    };

    const onBeforeDragStart = (value) => {
        const itemIndex = value && value.source ? value.source.index : null;
        if (itemIndex !== null) {
            // get items number
            const itemsNumber = items.length;
            setCanDragDown(itemIndex < itemsNumber - 1);
            setCanDragUp(itemIndex > 0);
        }
    };

    return (
        <div className='priority-list-question-container'>
            <div className='arrows'>
                <Fab className={`drag-direction-up ${canDragUp ? 'highlight' : ''}`}>
                    <ForwardIcon />
                </Fab>
                <Fab className={`drag-direction-down ${canDragDown ? 'highlight' : ''}`}>
                    <ForwardIcon />
                </Fab>
            </div>
            <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className='droppable'
                        >
                            {items && items.map((item, index) => (
                                <div key={index} className='draggable-container'>
                                    <div className='item-order-number'>
                                        <img src={pinRightGray} alt='pin-icon'/>
                                        <span className='item-number'>{index+1}</span>
                                    </div>
                                    <Draggable draggableId={`${index}`} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className='draggable'
                                            >
                                                <img src={dragIcon} alt='drag-icon' className='drag-icon' />
                                                {
                                                    item.custom_item ?
                                                        <div>
                                                            {
                                                                editMode ?
                                                                    <div className='editable'>
                                                                        <TextField
                                                                            label={template.item_placeholder_text ? template.item_placeholder_text : ''}
                                                                            className='custom-item-title-input'
                                                                            value={customItemTitle}
                                                                            onChange={handleTitleChange}
                                                                        />
                                                                        <CheckIcon className='confirm-icon action-icon edit-mode' onClick={handleTitleConfirm}/>
                                                                        <CancelIcon onClick={handleRemoveCustomItem} className='delete-icon action-icon edit-mode'/>
                                                                    </div> :
                                                                    <div className='editable'>
                                                                        <Typography id="discrete-custom-item" className='custom-item-title' gutterBottom>
                                                                            {customItemTitle}
                                                                        </Typography>
                                                                        <CreateIcon className='edit-icon action-icon' onClick={handleEditModeOpen}/>
                                                                        <CancelIcon onClick={handleRemoveCustomItem} className='delete-icon action-icon'/>
                                                                    </div>
                                                            }
                                                        </div> :
                                                        <div className='item-text'>
                                                            {item.item_text}
                                                        </div>
                                                }
                                            </div>
                                        )}
                                    </Draggable>
                                </div>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {
                    template.item_other_allowed &&
                    <div className='add-item'>
                        <Button
                            variant="contained"
                            color="default"
                            className='add-item-button'
                            startIcon={<ControlPointIcon  className='add-icon' style={getStyle('add-icon', language)}/>}
                            onClick={addItem}
                            disabled={items.findIndex(item => item.custom_item) !== -1}
                        >
                            {template.item_add_text ? template.item_add_text : t("add")}
                        </Button>
                    </div>
                }
            </DragDropContext>
            {requiredError && template.required && !hasAnswer() &&
            <AlertComponent text={t("errorPriorityList")} type='error'/>}
        </div>
    );
}

export default PriorityListQuestion;
