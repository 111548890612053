import React from 'react';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus-icon.svg';

// Define the styled button
const StyledButton = styled(Button)(() => ({
    borderRadius: '30px',
    padding: '2px 8px',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    fontWeight: '500',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    color: 'var(--transform-mediumGrey)',
    transition: 'background-color, color 0.3s ease-in-out',

    '& .MuiButton-label': {
        gap: '6px'
    },

    '&:hover': {
        backgroundColor: 'var(--buttons-lightestGrey)',
        boxShadow: 'none',
        color: 'var(--text-mediumGrey)',

        '& .MuiButton-startIcon svg path': {
            fill: 'var(--text-mediumGrey)',
        },
    },

    '& .MuiButton-startIcon': {
        margin: '0',

        '& svg path': {
            transition: 'fill 0.3s ease-in-out',
        },
    },
}));

// TextButton component
const TextButton = ({ onClick, title }) => {
    return (
        <StyledButton
            variant="contained"
            color="default"
            startIcon={<PlusIcon />}
            onClick={() => onClick()}
        >
            {title}
        </StyledButton>
    );
};

export default TextButton;
