import React from 'react';
import './section.scss';
import { useSelector } from 'react-redux'
import parse from 'html-react-parser';
import { Box } from '@material-ui/core';
import { getStyle } from '../styleUtil'

// Section component
function Section(props) {
    const { template } = props; // Get the template from the props
    const isMobileScreen = useSelector(state => state.isMobileScreen); // Get the isMobileScreen value from redux
    const language = useSelector(state => state.language); // Get the language from redux

    return (
        <Box className='section-container'>
            {
                template &&
                <Box className='section-header' style={getStyle('section-header', language)}>{template.question_text}</Box>
            }
            {
                template.section_description &&
                <Box className="section-description" style={{ ...getStyle('section-description', language), marginTop: '10px' }}> {parse(template.section_description)}</Box>
            }
        </Box>
    );
}

export default Section;
