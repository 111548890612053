import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash';
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";

import '../matrix-single-select/matrix-single-select-question.scss';
import {updateQuestionAnswer} from "../../redux/actions";
import AlertComponent from "../../input-components/alert-component";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
    },
}));

const addAnswer = (answer, value, itemId, items) => {
    let newAnswer = _.cloneDeep(answer);
    let itemIndex = answer.findIndex(a => a.item_id === itemId);
    if (itemIndex !== -1) {
        newAnswer[itemIndex].answer.push(value);
    } else {
        let item = items.find(i => i.item_id === itemId);
        newAnswer.push({
            item_id: itemId,
            item_text: item ? item.item_text : '',
            answer: [value],
        })
    }

    return newAnswer;
};


const removeAnswer = (answer, value, itemId) => {
    let newAnswer = _.cloneDeep(answer);
    let itemIndex = answer.findIndex(a => a.item_id === itemId);
    if (itemIndex !== -1) {
        _.pull(newAnswer[itemIndex].answer, value);
    }

    return newAnswer;
};

const updateAnswer = (answer, value, itemId, items) => {
    let newAnswer = _.cloneDeep(answer);
    let itemIndex = answer.findIndex(a => a.item_id === itemId);
    if (itemIndex !== -1) {
        newAnswer[itemIndex].answer = value;
    } else {
        let item = items.find(i => i.item_id === itemId);
        newAnswer.push({
            item_id: itemId,
            item_text: item ? item.item_text : '',
            answer: value,
        })
    }

    return newAnswer;
};

function MatrixMultiSelectQuestion(props) {
    const { template } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useSelector(state => state.user);
    const questionAnswers = useSelector(state => state.questionAnswers);
    const requiredError = useSelector(state => state.requiredError);
    const isMobileScreen = useSelector(state => state.isMobileScreen);
    const [answer, setAnswer] = useState([]);

    useEffect(() => {
        const questionAnswer = questionAnswers.find(qa => qa.questionId === template.element_id);
        setAnswer(questionAnswer ? questionAnswer.answer : []);
    }, [template, questionAnswers]);

    const handleSelectChange = (value, itemId, checked, mobile) => {
        console.log(`select item changed! value: ${value}, item id: ${itemId}, checked: ${checked}`);
        let newAnswer;
        if (mobile) {
            newAnswer = updateAnswer(answer, value, itemId, template.items);
        } else {
            if (checked) {
                newAnswer = addAnswer(answer, value, itemId, template.items);
            } else {
                newAnswer = removeAnswer(answer, value, itemId);
            }
        }
        setAnswer(newAnswer);
        dispatch(updateQuestionAnswer(template.element_id, newAnswer, user.token, questionAnswers));
    };

    const checked = (itemAnswer, option) => {
        return itemAnswer ? itemAnswer.answer.includes(option) : false;
    };

    const renderSelectText = (selected) => {
        // render either '' if nothing, 'firstValue' if one value, 'firstValue, ...' if more values
        return  selected.length > 1 ? `${selected[0]}, ...` : (selected.length ? selected[0] : '');
    };

    return template ? (
        <div className='matrix-single-select-question-container'>
            {
                isMobileScreen ?
                    <div className='matrix-mobile'>
                        {
                            template.items.map((item, index) => {
                                const itemAnswer = answer.find(a => a.item_id === item.item_id);
                                return (
                                    <div className='select-container' key={`select-item-${index}`}>
                                        <div className='item-text text-bold'>{item.item_text}</div>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="mutiple-checkbox-label">{template.item_placeholder_text ? template.item_placeholder_text : t("select")}</InputLabel>
                                            <Select
                                                variant="outlined"
                                                labelId="mutiple-checkbox-label"
                                                id="mutiple-checkbox"
                                                multiple
                                                value={itemAnswer ? itemAnswer.answer : []}
                                                onChange={e => handleSelectChange(e.target.value, item.item_id, !checked(itemAnswer, e.target.value), true)}
                                                input={<Input />}
                                                renderValue={(selected) => renderSelectText(selected)}
                                            >
                                                {
                                                    template.item_options.map((option, index) => {
                                                        return (
                                                            <MenuItem key={`menu-item-${index}`} value={option} >
                                                                <Checkbox checked={checked(itemAnswer, option)} />
                                                                <ListItemText primary={option}/>
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                )
                            })
                        }
                    </div> :
                    <div className='matrix-desktop'>
                        <div className='option-text'>
                            {
                                template.items.map((item, index) => {
                                    return (<div key={`item-text-${index}`} className='item-text text-bold'>{item.item_text}</div>);
                                })
                            }
                        </div>
                        <div className='options'>
                            {
                                template.item_options.map((option, index) => {
                                    return (
                                        <div className='option-container' key={index}>
                                            <div className='item-option text-bold' key={`item-option-${index}`}>{option}</div>
                                            {
                                                template.items.map((item, index) => {
                                                    const itemAnswer = answer.find(a => a.item_id === item.item_id);
                                                    return (
                                                        <Checkbox
                                                            key={`checkbox-item-${index}`}
                                                            checked={checked(itemAnswer, option)}
                                                            onChange={e => handleSelectChange(e.target.value, item.item_id, !checked(itemAnswer, option))}
                                                            value={option}
                                                            name="checkbox"
                                                            className='checkbox'
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
            }
            {
                requiredError && template.required && answer.length === 0 &&
                <AlertComponent text={t("required")} type={'error'}/>
            }
        </div>
    ) : (<div>loading</div>);
}

export default MatrixMultiSelectQuestion;
