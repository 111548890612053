import {
    NEXT_PAGE_SELECTED,
    PAGE_SELECTED,
    PREVIOUS_PAGE_SELECTED,
    QUESTION_ADDITIONAL_ITEMS_ADD,
    QUESTION_ADDITIONAL_ITEMS_EDIT,
    QUESTION_ADDITIONAL_ITEMS_REMOVE,
    QUESTION_ANSWER_CHANGED,
    SCREEN_TYPE_CHANGE,
    SURVEY_SUBMITTED,
    TEMPLATE_FETCHED,
    USER_TOKEN_SET,
    PAGE_REQUIRED_QUESTIONS_SET,
    SET_ERROR,
    HIDE_ERROR,
    QUESTION_RESPONSES_FETCHED,
    RESPONDENT_LIST_FETCHED,
    PAGE_QUESTION_ERROR_CHANGE,
    CLICKED_SUBMIT,
    METADATA_FETCHED,
    METADATA_ERROR,
    PAGE_QUESTION_ERROR_ADD,
    PAGE_QUESTION_ERROR_REMOVE,
    PREVIEW_SET,
    CUSTOM_LIST_FETCHED,
    RESPONDENTS_STATUS_CHANGE,
} from "../../assets/action-constants";
import template from '../../assets/questions-template';
import templateNo from '../../assets/questions-template-lang-no';
import {
    createAnonymousRespondent,
    getQuestionTemplate,
    postQuestionAnswer,
    submitSurvey,
    getRespondentsList,
    getResponses,
    getLists,
    getSurveyMetadata,
    postRespondentStatus
} from '../../services/db-service';

export const setAnonymousRespondent = (surveyId, token) => {
    console.log('set anon respondent, surevey id: ' + surveyId + ', token: ' + token);
    return dispatch => {
        return createAnonymousRespondent(surveyId, token).then(res => {
            console.log('redux action res: ', res);
            return dispatch({
                type: USER_TOKEN_SET,
                payload: {token},
            })
        }).catch(err => {
            console.log('error when creating anon respondent: ', err);
            return dispatch({
                type: METADATA_ERROR,
                // todo use translator
                payload: {message: 'Beklager her har det skjedd en uventet feil. Vennligst kontakt support@transforminsight.com'}
            })
        })
    }
};

export const getQuestionsTemplate = (token, preview) => {
    console.log('get template');
    return dispatch => {
        return getQuestionTemplate(token, preview).then(templateDb => {
            console.log('template: ', templateDb);
            return dispatch({
                type: TEMPLATE_FETCHED,
                payload: templateDb,
            })
        }).catch(err => {
            console.log('error when fetching template: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when fetching template'
            })
        })
    }
};

export const getQuestionResponses = (token, preview) => {
    console.log('get responses');
    return dispatch => {
        return getResponses(token, preview).then(responses => {
            console.log('responses: ', responses);
            return dispatch({
                type: QUESTION_RESPONSES_FETCHED,
                payload: responses
            })
        }).catch(err => {
            console.log('error when fetching responses: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when fetching responses'
            })
        })
    }
};

export const getRespondents = (token, preview) => {
    console.log('get respondents');
    return dispatch => {
        return getRespondentsList(token, preview).then(respondents => {
            console.log('respondents: ', respondents);
            return dispatch({
                type: RESPONDENT_LIST_FETCHED,
                payload: respondents
            })
        }).catch(err => {
            console.log('error when fetching respondents: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when fetching respondents'
            })
        })
    }
};

export const getCustomLists = (token, preview) => {
    console.log('get custom lists');
    return dispatch => {
        return getLists(token, preview).then(res => {
            return dispatch({
                type: CUSTOM_LIST_FETCHED,
                payload: { lists: res },
            })
        }).catch(err => {
            console.log('error getting lists: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when getting lists'
            })
        })
    }
  }

export const getMetadata = (token, preview) => {
    console.log('get metadata');
    return dispatch => {
        return getSurveyMetadata(token, preview).then(metadata => {
            return dispatch({
                type: METADATA_FETCHED,
                payload: metadata
            })
        }).catch(err => {
            console.log('error when fetching metadata: ', err);
            let errorMessage = 'Error when fetching metadata';
            if (err.response) {
                if (err.response.status === 401) {
                    errorMessage = 'Beklager her har det skjedd en uventet feil. Vennligst kontakt support@transforminsight.com';
                } else if (err.response.status === 403) {
                    errorMessage = 'Takk. Undersøkelsen er allerede sendt inn';
                } else {
                    errorMessage = err.response.data.message;
                }
            }
            return dispatch({
                type: METADATA_ERROR,
                payload: {message: errorMessage}
            })
        })
    }
};

export const goToNextPage = () => {
    return dispatch => {
        return dispatch({
            type: NEXT_PAGE_SELECTED,
        });
    }
};

export const goToPreviousPage = () => {
    return dispatch => {
        return dispatch({
            type: PREVIOUS_PAGE_SELECTED,
        });
    }
};

export const goToPage = (pageIndex) => {
    return dispatch => {
        return dispatch({
            type: PAGE_SELECTED,
            payload: pageIndex,
        });
    }
};

export const setPageRequiredQuestions = (questionIds) => {
    return dispatch => {
        return dispatch({
            type: PAGE_REQUIRED_QUESTIONS_SET,
            payload: questionIds,
        });
    }
};

export const onSubmitClick = () => {
    return dispatch => {
        return dispatch({
            type: CLICKED_SUBMIT,
        });
    }
};

export const onSurveySubmit = (token) => {
    return (dispatch, getState) => {
        try {
            const {isPreview} = getState();
            if (!isPreview) {
                submitSurvey(token, isPreview);
            }
        } catch (e) {
            console.log('error while submitting survey: ', e);
            return dispatch({
                type: SET_ERROR,
                error: 'Error while submitting survey'
            })
        }
        return dispatch({
            type: SURVEY_SUBMITTED,
        });
    }
};

export const addAdditionalItem = (item, questionId) => {
    return dispatch => {
        return dispatch({
            type: QUESTION_ADDITIONAL_ITEMS_ADD,
            payload: {item, questionId}
        });
    }
};

export const removeAdditionalItem = (item, questionId) => {
    return dispatch => {
        return dispatch({
            type: QUESTION_ADDITIONAL_ITEMS_REMOVE,
            payload: {item, questionId}
        });
    }
};

export const editAdditionalItem = (item, questionId) => {
    return dispatch => {
        return dispatch({
            type: QUESTION_ADDITIONAL_ITEMS_EDIT,
            payload: {item, questionId}
        });
    }
};

export const updateQuestionAnswer = (questionId, answer, token, allAnswers) => {
    console.log('action: update question answer: ', answer);
    const firstAnswer = allAnswers.length === 0;
    return (dispatch, getState) => {
        try {
            const {isPreview} = getState();
            if (!isPreview) {
                postQuestionAnswer(questionId, answer, token, firstAnswer, isPreview);
            }
        } catch (e) {
            console.log('error while posting answer: ', e);
            return dispatch({
                type: SET_ERROR,
                error: 'Error while posting answer'
            })
        }
        return dispatch({
            type: QUESTION_ANSWER_CHANGED,
            payload: {questionId, answer}
        });
    }
};

export const updateRespondentsStatus = (token, respondentsStatus) => {
    console.log('action: update respondents status: ', respondentsStatus);
    return (dispatch, getState) => {
        try{
            const {isPreview} = getState();
            if (!isPreview) {
                postRespondentStatus(token, isPreview, respondentsStatus);
            }
        } catch (e) {
            console.log('error while posting respondents status: ', e);
            return dispatch({
                type: SET_ERROR,
                error: 'Error while posting respondents status'
            })
        } 
        return dispatch({
            type: RESPONDENTS_STATUS_CHANGE,
            payload: {respondentsStatus}
        });
    }
};

export const setScreenType = (isMobile) => {
    return dispatch => {
        return dispatch({
            type: SCREEN_TYPE_CHANGE,
            payload: {isMobile}
        });
    }
};

export const setUserToken = (token) => {
    return dispatch => {
        return dispatch({
            type: USER_TOKEN_SET,
            payload: {token}
        });
    }
};

export const setPreview = (preview) => {
    return dispatch => {
        return dispatch({
            type: PREVIEW_SET,
            payload: {preview}
        });
    }
};

export const setError = (error) => {
    return dispatch => {
        return dispatch({
            type: SET_ERROR,
            error: error
        })
    }
};

export const hideError = () => {
    return dispatch => {
        return dispatch({
            type: HIDE_ERROR
        })
    }
};

export const updatePageQuestionErrors = (pageQuestionErrors) => {
    return dispatch => {
        return dispatch({
            type: PAGE_QUESTION_ERROR_CHANGE,
            payload: {pageQuestionErrors},
        })
    }
};

export const addPageQuestionError = (questionId) => {
    return dispatch => {
        return dispatch({
            type: PAGE_QUESTION_ERROR_ADD,
            payload: {questionId},
        })
    }
};

export const removePageQuestionError = (questionId) => {
    return dispatch => {
        return dispatch({
            type: PAGE_QUESTION_ERROR_REMOVE,
            payload: {questionId},
        })
    }
};
