import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash';
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";

import './matrix-single-select-question.scss';
import {updateQuestionAnswer, addPageQuestionError, removePageQuestionError} from "../../redux/actions";
import AlertComponent from "../../input-components/alert-component";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
    },
}));

const updateAnswer = (answer, value, itemId, items) => {
    let newAnswer = _.cloneDeep(answer);
    let itemIndex = answer.findIndex(a => a.item_id === itemId);
    if (itemIndex !== -1) {
        newAnswer[itemIndex].answer = value;
    } else {
        let item = items.find(i => i.item_id === itemId);
        newAnswer.push({
            item_id: itemId,
            item_text: item ? item.item_text : '',
            answer: value,
        })
    }

    return newAnswer;
};

const allRequiredError = (template, answer) => {
    return template.all_required && answer.length !== template.items.length;
};

function MatrixSingleSelectQuestion(props) {
    const { template } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useSelector(state => state.user);
    const questionAnswers = useSelector(state => state.questionAnswers);
    const requiredError = useSelector(state => state.requiredError);
    const pageQuestionErrors = useSelector(state => state.pageQuestionErrors);
    const isMobileScreen = useSelector(state => state.isMobileScreen);
    const [answer, setAnswer] = useState([]);

    useEffect(() => {
        const questionAnswer = questionAnswers.find(qa => qa.questionId === template.element_id);
        setAnswer(questionAnswer ? questionAnswer.answer : []);
    }, [template, questionAnswers]);

    useEffect(() => {
        if (allRequiredError(template, answer) && !pageQuestionErrors.includes(template.element_id)) {
            dispatch(addPageQuestionError(template.element_id));
        } else if (template.all_required && answer.length === template.items.length) {
            dispatch(removePageQuestionError(template.element_id));
        }
    }, [answer]);

    const handleSelectChange = (value, itemId) => {
        console.log(`select item changed! value: ${value}, item id: ${itemId}`);
        const newAnswer = updateAnswer(answer, value, itemId, template.items);
        setAnswer(newAnswer);
        dispatch(updateQuestionAnswer(template.element_id, newAnswer, user.token, questionAnswers));
    };

    return template ? (
        <div className='matrix-single-select-question-container'>
            {
                isMobileScreen ?
                    <div className='matrix-mobile'>
                        {
                            template.items.map((item, index) => {
                                const itemAnswer = answer.find(a => a.item_id === item.item_id);
                                return (
                                    <div className='select-container' key={`select-item-${index}`}>
                                        <div className='item-text text-bold'>{item.item_text}</div>
                                        <FormControl className={classes.formControl} size='small'>
                                            <InputLabel id="demo-simple-select-label">{template.item_placeholder_text ? template.item_placeholder_text : t("select")}</InputLabel>
                                            <Select
                                                variant="outlined"
                                                labelId="simple-select-label"
                                                id="simple-select"
                                                value={itemAnswer ? itemAnswer.answer : ''}
                                                onChange={e => handleSelectChange(e.target.value, item.item_id)}
                                            >
                                                {
                                                    template.item_options.map((option, index) => {
                                                        return <MenuItem value={option} key={`menu-item-${index}`}>{option}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                )
                            })
                        }
                    </div> :
                    <div className='matrix-desktop'>
                        <div className='option-text'>
                            {
                                template.items.map((item, index) => {
                                    return (<div key={`item-text-${index}`} className='item-text text-bold'>{item.item_text}</div>);
                                })
                            }
                        </div>
                        <div className='options'>
                            {
                                template.item_options.map((option, index) => {
                                    return (
                                        <div className='option-container' key={index}>
                                            <div className='item-option text-bold' key={`item-option-${index}`}>{option}</div>
                                            {
                                                template.items.map((item, index) => {
                                                    const itemAnswer = answer.find(a => a.item_id === item.item_id);
                                                    return (
                                                        <Radio
                                                            key={`radio-item-${index}`}
                                                            checked={itemAnswer ? itemAnswer.answer === option : false}
                                                            onChange={e => handleSelectChange(e.target.value, item.item_id)}
                                                            value={option}
                                                            name="radio-button"
                                                            className='radio-button'
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
            }
            {
                requiredError && ((template.required && _.isEmpty(answer)) || allRequiredError(template, answer)) &&
                <AlertComponent text={t(template.all_required ? "allRequired" : "required")} type={'error'}/>
            }
        </div>
    ) : (<div>loading</div>);
}

export default MatrixSingleSelectQuestion;
