const sortSurveyQuestions = (questions) => {
    const firstQuestion = questions.find(q => !q.prevQuestion);
    console.log('First question found: ', firstQuestion)
    let list = [];
    if (!firstQuestion) {
        return questions;
    }
    list.push(firstQuestion);
    let nextQuestion = questions.find(q => q.id === firstQuestion.nextQuestion);
    console.log('Next question found: ', nextQuestion)
    while (nextQuestion) {
        console.log('next question !: ', nextQuestion)
        list.push(nextQuestion);
        nextQuestion = questions.find(q => q.id === nextQuestion.nextQuestion);
    }
    console.log('sorted questions: ', list);
    return list;
};

export const transformDbQuestions = (dbQuestions) => {
    return sortSurveyQuestions(dbQuestions.map(question => {
        return {
            element_id: question.id,
            ...question.question,
        }
    }))
};

export const transformQuestionToDbQuestions = (questions) => {

};

export const transformDbResponses = (responses) => {
    return responses.map(response => {
        const answer = JSON.parse(response.answer);
        return {
            questionId: response.id,
            answer: answer.answer,
        }
    });
};
