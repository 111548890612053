import axios from 'axios';
import { transformDbQuestions, transformDbResponses } from './data-transformer';

axios.defaults.baseURL = process.env.REACT_APP_CLIENT_DB_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

// anonymous
export function createAnonymousRespondent(surveyId, token) {
  return axios({
    method: 'post',
    url: '/anonymous',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      surveyId: surveyId,
      token: token,
    }
  }).then(res => {
    console.log('res: ', res);
    return res;
  })
}

export function getQuestionTemplate(token, preview) {
  return axios({
    method: 'get',
    url: '/questions',
    headers: {
      authorization: token,
      preview: JSON.stringify(preview),
    }
  }).then(res => {
    if (res) {
      const questionsTransformed = transformDbQuestions(res.data.questions);
      return {
        ...res.data,
        questions: questionsTransformed
      };
    }
    return null;
  })
}

export function getSurveyMetadata(token, preview) {
  return axios({
    method: 'get',
    url: '/survey',
    headers: {
      authorization: token,
      preview: JSON.stringify(preview),
    }
  }).then(res => {
    if (res) {
      return res.data || null;
    }
    return null;
  })
}

export function postQuestionAnswer(questionId, answer, token, firstAnswer, preview) {
  return axios({
    method: 'post',
    url: '/responses',
    headers: {
      authorization: token,
      preview: JSON.stringify(preview),
    },
    params: {
      questionId: questionId,
      firstAnswer: firstAnswer
    },
    data: {
      answer: answer,
    }
  }).then(res => {
    console.log('res: ', res);
    return res;
  })
}

export function submitSurvey(token, preview) {
  return axios({
    method: 'post',
    url: '/survey',
    headers: {
      authorization: token,
      preview: JSON.stringify(preview),
    },
  }).then(res => {
    console.log('res: ', res);
    return res;
  })
}

export function getRespondentsList(token, preview) {
  return axios({
    method: 'get',
    url: '/lists',
    headers: {
      authorization: token,
      preview: JSON.stringify(preview),
    },
    params: {
      name: 'respondents'
    },
  }).then(res => {
    return res.data;
  })
}
export function getLists(token, preview) {
  return axios({
    method: 'get',
    url: '/lists',
    headers: {
      authorization: token,
      preview: JSON.stringify(preview),
    },
    params: {
      name: 'custom'
    },
  }).then(res => {
    return res.data;
  })
}

export function getResponses(token, preview) {
  return axios({
    method: 'get',
    url: '/responses',
    headers: {
      authorization: token,
      preview: JSON.stringify(preview),
    }
  }).then(res => {
    if (res) {
      return transformDbResponses(res.data);
    }
    return null;
  })
}

export function postRespondentStatus(token, preview, statusUpdate) {
  return axios({
    method: 'post',
    url: '/responses',
    headers: {
      authorization: token,
      preview: JSON.stringify(preview),
    },
    params: {
      statusUpdate: statusUpdate,
    },
  }).then(res => {
    console.log('res: ', res);
    return res;
  })
}