import _ from 'lodash';

const getNonEmptyAnswers = (questionAnswers) => {
    return questionAnswers.filter(qa => {
        return (qa.answer !== null && qa.answer !== undefined) ? (_.isObject(qa.answer) ? !_.isEmpty(qa.answer) : true) : false;
    });
};

const requiredQuestionsAnswered = (requiredQuestions, questionAnswers) => {
    // we don't want to count empty arrays, empty objects etc as answers
    const nonEmptyAnswers = getNonEmptyAnswers(questionAnswers);

    // check if all required question ids are in question answers array
    return _.difference(requiredQuestions, nonEmptyAnswers.map(qa => qa.questionId)).length === 0;
};

export const pageCompleted = (requiredQuestions, questionAnswers, pageQuestionErrors) => {
    return requiredQuestionsAnswered(requiredQuestions, questionAnswers) && _.isEmpty(pageQuestionErrors);
};

export const questionsAnswered = (questions, questionAnswers) => {
    const nonEmptyAnswers = getNonEmptyAnswers(questionAnswers);
    const nonEmptyAnswerQuestionIds = nonEmptyAnswers.map(a => a.questionId);
    return questions.filter(q => nonEmptyAnswerQuestionIds.includes(q.element_id));
};

export const lastQuestionAnswered = (questions, questionAnswers) => {
    const nonEmptyAnswers = getNonEmptyAnswers(questionAnswers);
    const tempQuestions = _.cloneDeep(questions);
    const reversedQuestions = _.reverse(tempQuestions);
    const answeredQuestionIds = nonEmptyAnswers.map(a => a.questionId);
    return reversedQuestions.find(question => answeredQuestionIds.includes(question.element_id));
};
