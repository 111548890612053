import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Box, Typography } from '@material-ui/core';
import { colorTheme } from "../../assets/theme";
import { styled } from '@material-ui/core/styles';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import AlertComponent from "../../input-components/alert-component";
import useTextBoxQuestion from './../../hooks/questions/use-text-box-question';

// Define the style TextArea
const StyledTextArea = styled(TextareaAutosize)({
    width: '100%',
    resize: 'none',
    borderRadius: '4px',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    border: '1px solid ' + colorTheme.theme.lines.lightGrey,
    padding: '14.5px 12px',
    color: colorTheme.theme.text.darkGrey,
    fontSize: '15px',
    letterSpacing: '0.15px',
    transition: 'border 0.3s',
    boxSizing: 'border-box',
    '&:focus, &:hover': {
        outline: 'none',
        border: '1px solid ' + colorTheme.theme.text.mediumGrey,
    }
});

function TextBoxQuestion(props) {
    const { template } = props; // Destructure the props object
    const { t } = useTranslation(); // Get the t function from the useTranslation hook
    const user = useSelector(state => state.user); // Get the user object from the Redux store

    const {
        answer,
        isMobileScreen,
        requiredError,
        handleTextareaChange,
        getRemainingCharactersColor
    } = useTextBoxQuestion(template, user); // Get the necessary variables and functions from the useTextBoxQuestion hook

    return (
        <Box className='text-box-question-container'>
            <StyledTextArea
                minRows={isMobileScreen ? 10 : 15}
                maxLength={template.max_characters}
                placeholder={template.item_placeholder && template.item_placeholder_text ? template.item_placeholder_text : t("typeYourAnswer")}
                onChange={handleTextareaChange}
                value={answer ? answer : ''}
            />
            <Box className='info' display={'flex'} flexDirection={'column'} width={'100%'} mt={0.75}>
                <Typography style={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0.15px' }}>
                    {template.max_characters ? (
                        <>
                            {t("charactersLeft")}:
                            <span style={{ color: getRemainingCharactersColor(), fontWeight: '600', marginLeft: '5px' }}>
                                {template.max_characters - (answer ? answer.length : 0)}
                            </span>
                        </>
                    ) : ''}
                </Typography>
                {requiredError && template.required && !answer && <AlertComponent text={`**${t("questionRequired")}** ${t("provideAnswer")}`} type='error' />}
            </Box>
        </Box>
    );
}

export default TextBoxQuestion;
