import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import placeholderLogo from "../../assets/images/placeholder-logo.svg";
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ReactComponent as TransformLogo } from '../../assets/images/transform-white.svg';

// Header component
function Header(props) {
    const { isPreview, companyLogo } = props; // Get isPreview and companyLogo from props
    const theme = useTheme(); // Get theme
    const isSmall = useMediaQuery(theme.breakpoints.down('xs')); // Check if screen size is small

    return (
        <Box className={`header-container`} style={{ display: 'flex', justifyContent: isSmall ? 'center' : 'space-between', width: '100%', minHeight: '160px', maxHeight: '160px', background: 'var(--colors-main)', padding: '45px 56px', position: 'relative', boxSizing: 'border-box', marginTop: isPreview ? '24px' : '0px' }}>
            {
                isPreview &&
                <Box className='preview' style={{ position: 'absolute', width: '100%', top: '-24px', left: '0', zIndex: '1001', backgroundColor: 'var(--colors-highlight)', color: 'var(--colors-fontLight)', textTransform: 'uppercase', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <VisibilityOutlinedIcon />
                    <Typography variant="body2" style={{ marginLeft: '6px', fontSize: '14px', fontWeight: '600' }}>Test Mode</Typography>
                </Box>
            }
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img className="company-logo" src={companyLogo ? companyLogo : placeholderLogo} alt="company-logo" style={{ objectFit: 'cover', width: '100%', maxWidth: '200px' }} />
            </Box>
            {
                !isSmall &&
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px' }}>
                    <Typography variant="body1" style={{ color: 'var(--colors-fontLight)', fontSize: '14px', lineHeight: '16px' }}>Powered by</Typography>
                    <TransformLogo />
                </Box>
            }

        </Box>
    )
}

export default Header;
