import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {hideError} from "../redux/actions";
import _ from 'lodash';

import './error-notification-components.scss';
import AlertComponent from "./alert-component";

const ErrorNotification = (props) => {
    const error = useSelector(state => state.error);
    const [alerts, setAlerts] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (error) {
            let newAlerts = _.cloneDeep(alerts);
            newAlerts.push({
                error: error
            });
            setAlerts(newAlerts);
            dispatch(hideError());
        }
    }, [error]);

    function handleClose(){
        dispatch(hideError());
    }

    return (
        <div className='error-notifications'>
            {
                alerts.map((error, index) => {
                    return <AlertComponent key={index} text={error.error} type='warning'/>
                })
            }
        </div>
    )
};

export default ErrorNotification;
