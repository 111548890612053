// questions
export const QUESTION_ANSWER_CHANGED = 'QUESTION_ANSWER_CHANGED';
export const QUESTION_ADDITIONAL_ITEMS_ADD = 'QUESTION_ADDITIONAL_ITEMS_ADD';
export const QUESTION_ADDITIONAL_ITEMS_REMOVE = 'QUESTION_ADDITIONAL_ITEMS_REMOVE';
export const QUESTION_ADDITIONAL_ITEMS_EDIT = 'QUESTION_ADDITIONAL_ITEMS_EDIT';
export const PAGE_REQUIRED_QUESTIONS_SET = 'PAGE_REQUIRED_QUESTIONS_SET';
export const PAGE_QUESTION_ERROR_CHANGE = 'PAGE_QUESTION_ERROR_CHANGE';
export const PAGE_QUESTION_ERROR_ADD = 'PAGE_QUESTION_ERROR_ADD';
export const PAGE_QUESTION_ERROR_REMOVE = 'PAGE_QUESTION_ERROR_REMOVE';

// db
export const TEMPLATE_FETCHED = 'TEMPLATE_FETCHED';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const PREVIEW_SET = 'PREVIEW_SET';
export const QUESTION_RESPONSES_FETCHED = 'QUESTION_RESPONSES_FETCHED';
export const RESPONDENT_LIST_FETCHED = 'RESPONDENT_LIST_FETCHED';
export const CUSTOM_LIST_FETCHED = 'CUSTOM_LIST_FETCHED';
export const METADATA_FETCHED = 'METADATA_FETCHED';
export const METADATA_ERROR = 'METADATA_ERROR';

// flow
export const PAGE_SELECTED = 'PAGE_SELECTED';
export const PREVIOUS_PAGE_SELECTED = 'PREVIOUS_PAGE_SELECTED';
export const NEXT_PAGE_SELECTED = 'NEXT_PAGE_SELECTED';
export const SURVEY_SUBMITTED = 'SURVEY_SUBMITTED';
export const SCREEN_TYPE_CHANGE = 'SCREEN_TYPE_CHANGE';
export const SET_ERROR = "SET_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";
export const CLICKED_SUBMIT = 'CLICKED_SUBMIT';

// other
export const RESPONDENTS_STATUS_CHANGE = 'RESPONDENTS_STATUS_CHANGE';
