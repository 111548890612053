import { createTheme } from '@material-ui/core/styles'; // Import the createTheme function from MUI

// Create a theme instance with the createTheme function
const colorTheme = createTheme({
    theme: {
        transform: {
            mediumGrey: '#7B7D89'
        }
        ,
        text: {
            black: "#231F20",
            darkGrey: "#282828",
            mediumGrey: "#4F4F4F",
            lightGrey: "#828282",
            red: "#E23D32",
            orange: "#FF973A",
            green: "#4BC265",
        },
        lines: {
            lightGrey: "#D7D7D7",
        },
        colors: {
            main: '#203455',
            highlight: '#FFA500',
            lightHighlight: '#E9CABFDB',
            fontDark: '#333333',
            backgroundMedium: '#666666',
            backgroundLight: '#EDEDED',
            fontLight: '#FFFFFF',
            redWarning: '#E23D32'
        },
        buttons: {
            lightestGrey: '#F0F0F0'
        }
    },
});

// Create a function to set the root variables
const createRootVariables = () => {
    const head = document.head; // Get the head element
    const style = document.createElement('style'); // Create a new style element

    const { theme } = colorTheme;

    let styles = '';

    for (const category in theme) {
        for (const key in theme[category]) {
            styles += `--${category}-${key}: ${theme[category][key]}; `;
        }
    }

    // Set the style element's text content to the root variables
    style.textContent = `:root { ${styles} }`;

    // Append the style element to the head
    head.appendChild(style);
};

// Export the colorTheme and createRootVariables functions
export { colorTheme, createRootVariables };

