import i18n from 'i18next';
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translations: {
            welcome: 'Welcome',
            confirmNameText: 'If this is your name, please select YES and move forward.',
            yes: 'yes',
            no: 'no',
            nameNotConfirmed: 'Please use your unique link to the survey, which you received via email.',
            next: 'Next',
            previous: 'Previous',
            charactersLeft: 'Characters left',
            addNew: 'Add New',
            total: 'Total',
            addNewContact: 'Add New Contact',
            search: 'Search',
            required: 'Required',
            answer: 'Answer',
            add: 'Add',
            submit: 'Submit',
            itemsLeft: 'Left',
            itemsLeftToAdd: 'Items left to add',
            minItemsRequired: 'Minimum items required',
            maxItemsLeft: 'Maximum items allowed',
            submitSuccess: 'Successfully submitted survey!',
            errorTokenExpired: 'Thanks. Survey has already been submitted',
            errorTokenInvalid: 'Sorry an unexpected error happened. Please contact support@transforminsight.com',
            errorPriorityList: 'To continue, please change rank (position) for at least one item in the list',
            other: 'Other',
            sum: 'total: ',
            pageNotFound: 'Page Not Found!',
            itemName: 'item name',
            select: 'Select Answer',
            allRequired: 'You must provide an answer for each item',
            restartSurveyTitle: 'Continue from the last question you completed?',
            restartSurveyText: 'We saved all your answers from your previous session. Do you wish to continue from the last answered question or start from the first page?',
            restartSurveyFirstPage: 'FIRST PAGE',
            restartSurveyContinue: 'CONTINUE',
            answerInputInfoPt1: 'This question requires a minimum of ',
            answerInputInfoPt2Select: 'selections.',
            answerInputInfoPt2Text: 'answers.',
            understandTerms: 'I understand the terms',
            typeYourAnswer: 'Type Your Answer here...',
            questionRequired: 'This question is required.',
            provideAnswer: 'Please provide an answer to proceed.',
            addOtherAnswer: 'Add other answer',
            addAnswer: 'Add Answer',
            pleaseAdjust: 'Please adjust',
            eachSlider: 'each slider',
            toContinue: 'to continue.',
            adjustOneSlider: 'Please adjust at least one slider to continue.',
            makeSelection: 'Please make a selection from',
            additionalOptions: 'Additional options for this question will appear based on your choice.',
            cancel: 'Cancel',
            save: 'Save',
        }
    },
    no: {
        translations: {
            welcome: 'Velkommen',
            confirmNameText: 'Hvis dette er ditt navn, vennligst velg JA og fortsett NESTE.',
            yes: 'ja',
            no: 'nei',
            nameNotConfirmed: 'Vennligst benytt din unike link til spørreundersøkelsen, som du har mottatt per epost',
            next: 'Neste',
            previous: 'Forrige',
            charactersLeft: 'Bokstaver igjen',
            addNew: 'Legg til',
            total: 'Totalt',
            addNewContact: 'Legg til en ny kontakt',
            search: 'Søk',
            required: 'Obligatorisk',
            answer: 'Svar',
            add: 'Legg til',
            submit: 'Send inn',
            itemsLeft: 'Igjen',
            submitSuccess: 'Takk. Ditt svar på undersøkelsen er sent inn.',
            errorTokenExpired: 'Takk. Undersøkelsen er allerede sendt inn',
            errorTokenInvalid: 'Beklager her har det skjedd en uventet feil. Vennligst kontakt support@transforminsight.com',
            errorPriorityList: 'For å fortsette må du rangere (flytte) minst ett element i listen',
            other: 'Annet, spesifiser',
            sum: 'totalt: ',
            pageNotFound: 'Side ikke funnet!',
            itemName: 'gjenstandsnavn',
            select: 'Velg svar',
            allRequired: 'Du må angi et svar for hvert alternativ',
            restartSurveyTitle: 'Fortsette fra det siste spørsmålet du fullførte?',
            restartSurveyText: 'Vi lagret alle svarene dine fra forrige økt. Ønsker du å fortsette fra det siste besvarte spørsmålet eller fortsette fra første side?',
            restartSurveyFirstPage: 'FØRSTE SIDE',
            restartSurveyContinue: 'FORTSETT',
            answerInputInfoPt1: 'Dette spørsmålet krever minst ',
            answerInputInfoPt2Select: 'utfylte svar.',
            answerInputInfoPt2Text: 'tekst.',
            understandTerms: 'Jeg forstår vilkårene',
            typeYourAnswer: 'Skriv svaret ditt her...',
            questionRequired: 'Dette spørsmålet er påkrevd.',
            provideAnswer: 'Vennligst gi et svar for å fortsette.',
            addOtherAnswer: 'Legg til annet svar',
            addAnswer: 'Legg til svar',
            pleaseAdjust: 'Vennligst juster',
            eachSlider: 'hver glidebryter',
            toContinue: 'for å fortsette.',
            adjustOneSlider: 'Vennligst juster minst en glidebryter for å fortsette.',
            makeSelection: 'Vennligst gjør et valg fra',
            additionalOptions: 'Ytterligere alternativer for dette spørsmålet vil vises basert på ditt valg.',
            minItemsRequired: 'Minimum antall kreves',
            maxItemsLeft: 'Maksimalt antall tillatt',
            itemsLeftToAdd: 'Gjenstander igjen å legge til',
            cancel: 'Avbryt',
            save: 'Lagre',
        }
    },
    se: {
        translations:
        {
            welcome: 'Välkommen',
            confirmNameText: 'Om detta är ditt namn, vänligen välj JA och fortsätt NÄSTA.',
            yes: 'ja',
            no: 'nej',
            nameNotConfirmed: 'ANvänd den personliga länken till undersökningen som du har fått via e-post',
            next: 'Nästa',
            previous: 'Tillbaka',
            charactersLeft: 'Bokstäver kvar',
            addNew: 'Lägg till',
            total: 'Totalt',
            addNewContact: 'Lägg till en ny kontakt',
            search: 'Sök',
            required: 'Obligatorisk',
            answer: 'Svar',
            add: 'Lägg till',
            submit: 'Skicka in',
            itemsLeft: 'Igen',
            submitSuccess: 'Tack för att du tog dig tid att svara på undersökningen. Ditt svar har skickats.',
            errorTokenExpired: 'Tack. Undersökningen har redan skickats.',
            errorTokenInvalid: 'Tyvärr inträffade ett oväntat fel, vänligen kontakta support@transforminsight.com',
            errorPriorityList: 'För att fortsätta måste du rangordna (flytta) flytta minst ett objekt i listan',
            other: 'Annat, specificera',
            sum: 'totalt: ',
            pageNotFound: 'Sidan hittades inte',
            itemName: 'objektnamn',
            select: 'Välj svar',
            allRequired: 'Du måste ange ett svar för varje alternativ',
            restartSurveyTitle: 'Fortsätt från den sista frågan du har slutfört',
            restartSurveyText: 'Vi sparade alla dina svar från föregående session. Vill du fortsätta från den senast besvarade frågan eller starta från början',
            restartSurveyFirstPage: 'Starta från början',
            restartSurveyContinue: 'FORTSÄTT',
            answerInputInfoPt1: 'Denna fråga kräver minst ',
            answerInputInfoPt2Select: 'svar.',
            answerInputInfoPt2Text: 'text.',
            understandTerms: 'Jag förstår villkoren',
            typeYourAnswer: 'Skriv ditt svar här...',
            questionRequired: 'Denna fråga är obligatorisk.',
            provideAnswer: 'Vänligen ge ett svar för att fortsätta.',
            addOtherAnswer: 'Lägg till annat svar',
            addAnswer: 'Lägg till svar',
            pleaseAdjust: 'Vänligen justera',
            eachSlider: 'varje skjutreglage',
            toContinue: 'för att fortsätta.',
            adjustOneSlider: 'Vänligen justera minst en skjutreglage för att fortsätta.',
            makeSelection: 'Vänligen gör ett val från',
            additionalOptions: 'Ytterligare alternativ för denna fråga kommer att visas baserat på ditt val.',
            minItemsRequired: 'Minsta antal krävs',
            maxItemsLeft: 'Maximalt antal tillåtet',
            itemsLeftToAdd: 'Objekt kvar att lägga till',
            cancel: 'Avbryt',
            save: 'Spara',
        }
    },
    ar: {
        translations:
        {
            welcome: 'مرحبا',
            confirmNameText: 'إن كنت تشاهد اسمك، الرجاء اختيار نعم للمضي قدما',
            yes: 'نعم',
            no: 'لا',
            nameNotConfirmed: 'الرجاء استخدام رابط الاستبانة الخاص بك والذي وصلك عبر البريد الإلكتروني.',
            next: 'التالي',
            previous: 'السابق',
            charactersLeft: 'حرفا باقية',
            addNew: 'أضف جديد',
            total: 'الإجمالي',
            addNewContact: 'أضف جهة اتصال جديدة',
            search: 'ابحث',
            required: 'مطلوب',
            answer: 'أجب',
            add: 'أضف',
            submit: 'إرسال',
            itemsLeft: 'باقي',
            submitSuccess: 'تم تسليم الاستبانة بنجاح!',
            errorTokenExpired: 'شكرا. لقد تم إرسال الاستبانة',
            errorTokenInvalid: 'عذرا، حدث خطأ غير متوقع. الرجاء التواصل مع support@transforminsight.com',
            errorPriorityList: 'للاستمرار، الرجاء تغيير موضع عنصر واحد على الأقل في القائمة',
            other: 'أخرى',
            sum: 'الإجمالي: ',
            pageNotFound: 'الصفحة غير موجودة!',
            itemName: 'اسم العنصر',
            select: 'اختر إجابة',
            allRequired: 'يجب إضافة إجابة لكل عنصر',
            restartSurveyTitle: 'هل ترغب بالاستمرار من عند آخر سؤال قمت بالإجابة عليه؟',
            restartSurveyText: 'لقد قمنا بحفظ جميع أجوبتك من الجلسة الماضية، هل ترغب بالاستمرار من آخر سؤال قمت بالإجابة عليه أم البدء من جديد؟',
            restartSurveyFirstPage: 'الصفحة الأولى',
            restartSurveyContinue: 'استمرار',
            answerInputInfoPt1: 'هذا السؤال يتطلب على الأقل ',
            answerInputInfoPt2Select: 'اختيارات.',
            answerInputInfoPt2Text: 'إجابات.',
            understandTerms: 'أقبل الشروط والاحكام',
            typeYourAnswer: 'اكتب إجابتك هنا...',
            questionRequired: 'هذا السؤال مطلوب.',
            provideAnswer: 'الرجاء تقديم إجابة للمتابعة.',
            addOtherAnswer: 'إضافة إجابة أخرى',
            addAnswer: 'إضافة إجابة',
            pleaseAdjust: 'الرجاء ضبط',
            eachSlider: 'كل شريط تمرير',
            toContinue: 'للمتابعة.',
            adjustOneSlider: 'الرجاء ضبط شريط التمرير واحد على الأقل للمتابعة.',
            makeSelection: 'الرجاء اختيار من',
            additionalOptions: 'الخيارات الإضافية لهذا السؤال ستظهر استناداً إلى اختيارك.',
            minItemsRequired: 'الحد الأدنى للعناصر المطلوبة',
            maxItemsLeft: 'الحد الأقصى للعناصر المسموح بها',
            itemsLeftToAdd: 'العناصر المتبقية للإضافة',
            cancel: 'إلغاء',
            save: 'حفظ',

        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'no',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        fallbackLng: "en",
        debug: true,
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",
        useSuspense: false, // triggers <Suspense/> if loading translations from db not finished
    });

export default i18n;

