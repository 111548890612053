export const getStyle = (className, language) => {
    if (language === 'ar') {
        switch (className) {
            case 'App':
                return {
                    'direction': 'rtl'
                }
            case 'button-and-info':
            case 'button-and-in':
            case 'question-number':
            case 'section-header':
            case 'section-text':
                return {
                    'direction': 'rtl',
                    "textAlign": 'right'
                }
            case 'question-title':
                return {
                    'textAlign': 'right',
                    'fontStyle': 'normal',
                    'color': 'var(--colors-fontDark)',
                    'marginBottom': '30px',
                    'whiteSpace': 'pre-line',
                    'direction': 'rtl'
                }
            case 'subtitle':
                return {
                    'fontWeight': 'normal',
                    'fontSize': '15px',
                    'lineHeight': '18px',
                    'direction': 'ltr',
                    'textAlign': 'right'
                }
            case 'search-choose-question-container':
            case 'single-select-question-container':
            case 'continue-dialog':
            case 'section-description':
                return {
                    'textAlign': 'right',
                    'direction': 'rtl'
                }
            case 'items-left':
                return {
                    'border-radius': '20px',
                    'padding-right': '10px',
                    'marginBeft': ' 3px',
                    'textAlign': 'right',
                    'direction': 'rtl'
                }
            case 'add-icon':
                return {
                    'marginBeft': '5px'
                }
            case 'primary-button-label':
                return {
                    'flexDirection': 'row-reverse'
                }
            case 'question-number-span':
                return {
                    'marginLeft': '10px',
                }
            default:
                return {}
        }
    } else {
        if (className === 'question-title') {
            return {
                'textAlign': 'left',
                'fontStyle': 'normal',
                'color': 'var(--colors-fontDark)',
                'marginBottom': '24px',
                'whiteSpace': 'pre-line'
            }
        }
        if (className === 'subtitle') {
            return {
                'fontWeight': 'normal',
                'fontSize': '15px',
                'lineHeight': '20px',
                'marginTop': '10px',
            }
        }
        if (className === 'question-number-span') {
            return {
                'marginRight': '10px',
            }
        }    
        return {}
    }
}