import React from 'react';
import {useTranslation} from "react-i18next";

import './not-found-page.scss';

function NotFoundPage() {
    const { t } = useTranslation();

    return (
        <div className='not-found-page-container'>
            {t("pageNotFound")}
        </div>
    );
}

export default NotFoundPage;
