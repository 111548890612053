import React from 'react';
import useSingleSliderSinglePickerQuestion from '../../hooks/questions/use-single-slider-single-picker-question';
import SliderComponent from "../../input-components/slider-component";
import { Box } from '@material-ui/core';

// SingleSliderSinglePickerQuestion component
function SingleSliderSinglePickerQuestion(props) {
    const { template } = props; // Destructure props object

    const {
        sliderId,
        answer,
        requiredError,
        handleSliderChange,
    } = useSingleSliderSinglePickerQuestion(template); // Get necessary values and functions from custom hook

    return (
        <Box className="slider-container" style={{ marginTop: '70px', padding: '0px 14px' }}>
            <SliderComponent
                id={sliderId}
                title={template.item_text}
                minText={template.item_min_text}
                maxText={template.item_max_text}
                min={template.item_min}
                max={template.item_max}
                step={template.item_increment}
                defaultValue={template.item_default_value}
                value={answer ? answer : null}
                required={requiredError && template.required && answer === null}
                onChange={handleSliderChange}
            />
        </Box>
    );
}

export default SingleSliderSinglePickerQuestion;
