import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import {useSelector} from "react-redux";

import {lastQuestionAnswered} from "../services/questions-service";
import {useTranslation} from "react-i18next";
import {getStyle} from './../questions/styleUtil'

function RestartDialog(props) {
    const currentPageIndex = useSelector(state => state.currentPageIndex);
    const questionAnswers = useSelector(state => state.questionAnswers);
    const questionsTemplate = useSelector(state => state.questionsTemplate);
    const language = useSelector(state => state.language);
    const [open, setOpen] = useState(false);
    const [lastQuestion, setLastQuestion] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const question = lastQuestionAnswered(questionsTemplate, questionAnswers);
        console.log('last question: ', question);
        if (question) {
            setLastQuestion(question);
            setOpen(true);
        }
    }, []);

    const handleClose = (continueSurvey) => {
        if (continueSurvey && props.jumpToQuestion) {
            props.jumpToQuestion(lastQuestion.element_id);
        }
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='xs'
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t("restartSurveyTitle")}</DialogTitle>
            <DialogContent>
                <DialogContentText className="continue-dialog" style={getStyle('continue-dialog',language)} id="alert-dialog-description">
                    {t("restartSurveyText")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose(false)}
                    variant="contained"
                    color="default"
                >
                    {t("restartSurveyFirstPage")}
                </Button>
                <Button
                    onClick={() => handleClose(true)}
                    variant="contained"
                    color="default"
                    className='button-highlight'
                >
                    {t("restartSurveyContinue")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RestartDialog;
