import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import * as d3 from "d3";

import './radar-graph.scss';

const RadarChart = (id, data, options) => {
    let cfg = {
        w: 600,				//Width of the circle
        h: 600,				//Height of the circle
        margin: {top: 20, right: 20, bottom: 20, left: 20}, //The margins of the SVG
        levels: 3,				//How many levels or inner circles should there be drawn
        maxValue: 1, 			//What is the value that the biggest circle will represent
        labelFactor: 1.25, 	//How much farther than the radius of the outer circle should the labels be placed
        wrapWidth: 60, 		//The number of pixels after which a label needs to be given a new line
        opacityArea: 0.35, 	//The opacity of the area of the blob
        dotRadius: 4, 			//The size of the colored circles of each blog
        opacityCircles: 0.1, 	//The opacity of the circles of each blob
        strokeWidth: 2, 		//The width of the stroke around each blob
        roundStrokes: false,	//If true the area and stroke will follow a round path (cardinal-closed)
        color: d3.scale.category10(),	//Color function
        dataPoints: [
            "Digital fitness",
            "Innovation",
            "Agility",
            "Collaboration",
            "Workspace",
            "Engagement",
            "Expertise",
            "Partners",
        ],
    };

    //Put all of the options into a variable called cfg
    if('undefined' !== typeof options){
        for(let i in options){
            if('undefined' !== typeof options[i]){ cfg[i] = options[i]; }
        }
    }

    //If the supplied maxValue is smaller than the actual one, replace by the max in the data
    let maxValue = Math.max(cfg.maxValue, d3.max(data, function(i){return d3.max(i.map(function(o){return o.value;}))}));

    let allAxis = (data[0].map(function(i, j){return i.axis})),	//Names of each axis
        total = allAxis.length,					//The number of different axes
        radius = Math.min(cfg.w/2, cfg.h/2), 	//Radius of the outermost circle
        Format = d3.format('%'),			 	//Percentage formatting
        angleSlice = Math.PI * 2 / total;		//The width in radians of each "slice"

    //Scale for the radius
    let rScale = d3.scale.linear()
        .range([0, radius])
        .domain([0, maxValue]);

    // Create the container SVG and g

    //Remove whatever chart with the same id/class was present before
    d3.select(id).select("svg").remove();

    //Initiate the radar chart SVG
    let svg = d3.select(id).append("svg")
        .attr("width",  cfg.w + cfg.margin.left + cfg.margin.right)
        .attr("height", cfg.h + cfg.margin.top + cfg.margin.bottom)
        .attr("class", "radar"+id);
    //Append a g element
    let g = svg.append("g")
        .attr("transform", "translate(" + (cfg.w/2 + cfg.margin.left) + "," + (cfg.h/2 + cfg.margin.top) + ")");

    // Glow filter for some extra effects

    //Filter for the outside glow
    let filter = g.append('defs').append('filter').attr('id','glow'),
        feGaussianBlur = filter.append('feGaussianBlur').attr('stdDeviation','2.5').attr('result','coloredBlur'),
        feMerge = filter.append('feMerge'),
        feMergeNode_1 = feMerge.append('feMergeNode').attr('in','coloredBlur'),
        feMergeNode_2 = feMerge.append('feMergeNode').attr('in','SourceGraphic');

    // Draw the Circular grid

    //Wrapper for the grid & axes
    let axisGrid = g.append("g").attr("class", "axisWrapper");

    //Draw the background circles
    axisGrid.selectAll(".levels")
        .data(d3.range(1,(cfg.levels+1)).reverse())
        .enter()
        .append("circle")
        .attr("class", "gridCircle")
        .attr("r", function(d, i){return radius/cfg.levels*d;})
        .style("fill", "#CDCDCD")
        .style("stroke", "#CDCDCD")
        .style("fill-opacity", cfg.opacityCircles)
        .style("filter" , "url(#glow)");

    //Text indicating at what % each level is
    axisGrid.selectAll(".axisLabel")
        .data(d3.range(1,(cfg.levels+1)).reverse())
        .enter().append("text")
        .attr("class", "axisLabel")
        .attr("x", 4)
        .attr("y", function(d){return -d*radius/cfg.levels;})
        .attr("dy", "0.4em")
        .style("font-size", "10px")
        .attr("fill", "#737373")
        .text(function(d,i) {
            return maxValue * d/cfg.levels;
           // return Format(maxValue * d/cfg.levels);
        });

    // Draw the axes

    //Create the straight lines radiating outward from the center
    let axis = axisGrid.selectAll(".axis")
        .data(allAxis)
        .enter()
        .append("g")
        .attr("class", "axis");
    //Append the lines
    axis.append("line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", function(d, i){ return rScale(maxValue*1.1) * Math.cos(angleSlice*i - Math.PI/2); })
        .attr("y2", function(d, i){ return rScale(maxValue*1.1) * Math.sin(angleSlice*i - Math.PI/2); })
        .attr("class", "line")
        .style("stroke", "white")
        .style("stroke-width", "2px");

    //Append the labels at each axis
    axis.append("text")
        .attr("class", "legend")
        .style("font-size", "11px")
        .attr("text-anchor", "middle")
        .attr("dy", "0.35em")
        .attr("x", function(d, i){ return rScale(maxValue * cfg.labelFactor) * Math.cos(angleSlice*i - Math.PI/2); })
        .attr("y", function(d, i){ return rScale(maxValue * cfg.labelFactor) * Math.sin(angleSlice*i - Math.PI/2); })
        .text(function(d){return d})
        .call(wrap, cfg.wrapWidth);

    // Draw the radar chart blobs

    // The radial line function
    let radarLine = d3.svg.line.radial()
        .interpolate("linear-closed")
        .radius(function(d) { return rScale(d.value); })
        .angle((d, i) => {
            const dataIndex = cfg.dataPoints.findIndex(dp => dp === d.axis);
            return dataIndex*angleSlice;
        });

    if(cfg.roundStrokes) {
        radarLine.interpolate("cardinal-closed");
    }

    // Create a wrapper for the blobs
    let blobWrapper = g.selectAll(".radarWrapper")
        .data(data)
        .enter().append("g")
        .attr("class", "radarWrapper");

    // Append the backgrounds
    blobWrapper
        .append("path")
        .attr("class", "radarArea")
        .attr("d", function(d,i) { return radarLine(d); })
        .style("fill", function(d,i) { return cfg.color(i); })
        .style("fill-opacity", cfg.opacityArea)
        .on('mouseover', function (d,i){
            //Dim all blobs
            d3.selectAll(".radarArea")
                .transition().duration(200)
                .style("fill-opacity", 0.1);
            //Bring back the hovered over blob
            d3.select(this)
                .transition().duration(200)
                .style("fill-opacity", 0.7);
        })
        .on('mouseout', function(){
            //Bring back all blobs
            d3.selectAll(".radarArea")
                .transition().duration(200)
                .style("fill-opacity", cfg.opacityArea);
        });

    // Create the outlines
    blobWrapper.append("path")
        .attr("class", "radarStroke")
        .attr("d", function(d,i) { return d.length && d[0].userData ? "" : radarLine(d); })
        .style("stroke-width", cfg.strokeWidth + "px")
        .style("stroke", function(d,i) { return cfg.color(i); })
        .style("fill", "none")
        .style("filter" , "url(#glow)");

    // Append the circles
    const blobEnter = blobWrapper.selectAll(".radarCircle")
        .data(function(d,i) { return d; })
        .enter()

    blobEnter.append("circle")
        .attr("class", d => `radarCircle ${d.userData ? 'user-data' : ''}`)
        .attr("r", d => {return d.userData ? 15 : cfg.dotRadius})
        .attr("cx", (d, i) => {
            const dataIndex = cfg.dataPoints.findIndex(dp => dp === d.axis);
            return rScale(d.value) * Math.cos(angleSlice*dataIndex - Math.PI/2);
        })
        .attr("cy", (d, i) => {
            const dataIndex = cfg.dataPoints.findIndex(dp => dp === d.axis);
            return rScale(d.value) * Math.sin(angleSlice*dataIndex - Math.PI/2);
        })
        .style("fill", function(d,i,j) { return cfg.color(j); })
        .style("fill-opacity", 0.8);

    // append text inside circles only for user data
    blobEnter.append("text")
        .attr("class", d => 'radar-text')
        .attr("x", (d, i) => {
            const dataIndex = cfg.dataPoints.findIndex(dp => dp === d.axis);
            return rScale(d.value) * Math.cos(angleSlice*dataIndex - Math.PI/2);
        })
        .attr("y", (d, i) => {
            const dataIndex = cfg.dataPoints.findIndex(dp => dp === d.axis);
            return rScale(d.value) * Math.sin(angleSlice*dataIndex - Math.PI/2);
        })
        .attr("dx", function(d){return -3})
        .attr("dy", function(d){return 4})
        .attr("fill", function(d,i,j) { return cfg.color(j) === "#2D3AD1" ? "white" : "black"; })
        .text((d, i) => {
            return d.userData ? d.value : "";
        });

    // Append invisible circles for tooltip

    // Wrapper for the invisible circles on top
    let blobCircleWrapper = g.selectAll(".radarCircleWrapper")
        .data(data)
        .enter().append("g")
        .attr("class", "radarCircleWrapper");

    // Append a set of invisible circles on top for the mouseover pop-up
    blobCircleWrapper.selectAll(".radarInvisibleCircle")
        .data(function(d,i) { return d; })
        .enter().append("circle")
        .attr("class", "radarInvisibleCircle")
        .attr("r", d => {return d.userData ? 15 : cfg.dotRadius*1.5})
        .attr("cx", (d, i) => {
            const dataIndex = cfg.dataPoints.findIndex(dp => dp === d.axis);
            return rScale(d.value) * Math.cos(angleSlice*dataIndex - Math.PI/2);
        })
        .attr("cy", (d, i) => {
            const dataIndex = cfg.dataPoints.findIndex(dp => dp === d.axis);
            return rScale(d.value) * Math.sin(angleSlice*dataIndex - Math.PI/2);
        })
        .style("fill", "none")
        .style("pointer-events", "all")
        .on("mouseover", function(d,i) {
            // dont show tooltip for user data since the value is in the circle already
            if (!d.userData) {
                let newX =  parseFloat(d3.select(this).attr('cx')) - 10;
                let newY =  parseFloat(d3.select(this).attr('cy')) - 10;

                tooltip
                    .attr('x', newX)
                    .attr('y', newY)
                    .text(
                        d.value
                        //Format(d.value)
                    )
                    .transition().duration(200)
                    .style('opacity', 1);
            }
        })
        .on("mouseout", function(){
            tooltip.transition().duration(200)
                .style("opacity", 0);
        });

    // Set up the small tooltip for when you hover over a circle
    let tooltip = g.append("text")
        .attr("class", "tooltip")
        .style("opacity", 0);

    // Helper Function, taken from http://bl.ocks.org/mbostock/7555321
    // Wraps SVG text
    function wrap(text, width) {
        text.each(function() {
            var text = d3.select(this),
                words = text.text().split(/\s+/).reverse(),
                word,
                line = [],
                lineNumber = 0,
                lineHeight = 1.4, // ems
                y = text.attr("y"),
                x = text.attr("x"),
                dy = parseFloat(text.attr("dy")),
                tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy + "em");

            while (word = words.pop()) {
                line.push(word);
                tspan.text(line.join(" "));
                if (tspan.node().getComputedTextLength() > width) {
                    line.pop();
                    tspan.text(line.join(" "));
                    line = [word];
                    tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
                }
            }
        });
    }

};

function RadarGraph(props) {
    const { t } = useTranslation();
    console.log(props);

    useEffect(() => {
        let margin = {top: 100, right: 100, bottom: 100, left: 100},
            width = Math.min(700, window.innerWidth - 10) - margin.left - margin.right,
            height = Math.min(width, window.innerHeight - margin.top - margin.bottom - 20);

        // Legend titles
        let LegendOptions = ['Goal for 2022', 'Today\'s estimation'];

        // Data
        let data = [
            [
                {axis:"Digital fitness",value:8},
                {axis:"Innovation",value:7},
                {axis:"Agility",value:9},
                {axis:"Collaboration",value:7},
                {axis:"Workspace",value:5},
                {axis:"Engagement",value:4},
                {axis:"Expertise",value:6},
                {axis:"Partners",value:9}
            ],
            [
                {axis:"Digital fitness",value:3},
                {axis:"Innovation",value:5},
                {axis:"Agility",value:6},
                {axis:"Collaboration",value:4},
                {axis:"Workspace",value:3},
                {axis:"Engagement",value:3},
                {axis:"Expertise",value:5},
                {axis:"Partners",value:4}
            ],
        ];
        if (props.data) {
            data = [...data, ...props.data];
            console.log(data);
        }

        // Draw the Chart

        let color = d3.scale.ordinal()
            .range(["#02FAB0","#2D3AD1", "#2D3AD1", "#02FAB0"]);

        let radarChartOptions = {
            w: width,
            h: height,
            margin: margin,
            maxValue: 10,
            levels: 10,
            roundStrokes: true,
            color: color
        };
        //Call function to draw the Radar chart
        RadarChart(".radarChart", data, radarChartOptions);

        // Initiate legend
        const svg = d3.selectAll('.graph')
            .selectAll('svg')
            .append('svg')
            .attr("width", radarChartOptions.w+300)
            .attr("height", radarChartOptions.h)

        //Create the title for the legend (leave this here for easy adding of title)
        // const text = svg.append("text")
        //     .attr("class", "title")
        //     .attr('transform', 'translate(90,0)')
        //     .attr("x", radarChartOptions.w - 70)
        //     .attr("y", 10)
        //     .attr("font-size", "12px")
        //     .attr("fill", "#404040")
        //     .text("Legend test text");

        //Initiate Legend
        const legend = svg.append("g")
            .attr("class", "legend")
            .attr("height", 100)
            .attr("width", 200)
            .attr('transform', 'translate(90,20)');

        //Create colour squares
        legend.selectAll('rect')
            .data(LegendOptions)
            .enter()
            .append("rect")
            .attr("x", radarChartOptions.w - 65)
            .attr("y", function(d, i){ return i * 20;})
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function(d, i){ return color(i);});

        //Create text next to squares
        legend.selectAll('text')
            .data(LegendOptions)
            .enter()
            .append("text")
            .attr("x", radarChartOptions.w - 52)
            .attr("y", function(d, i){ return i * 20 + 9;})
            .attr("font-size", "11px")
            .attr("fill", "#737373")
            .text(function(d) { return d; });

    }, []);

    return (
        <div className="graph">
            <div className="radarChart"/>
        </div>
    );
}

export default RadarGraph;
