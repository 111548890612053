import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import './index.css';
import './assets/i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux'
import store from './redux/store';
import { createRootVariables } from './assets/theme'; // Import the createRootVariables function from the theme file

const tagManagerArgs = {
    gtmId: 'GTM-M8XPVDM',
    dataLayer: {},
};

TagManager.initialize(tagManagerArgs);

createRootVariables(); // Call the createRootVariables function to set the root variables

ReactDOM.render(

    <Provider store={store}>
        <BrowserRouter basename='/'>
            <App />
        </BrowserRouter>
    </Provider >,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

