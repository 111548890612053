import React from "react";

import './footer.scss';
import transformLogoSmall from "../../assets/images/black_transform.svg";

function Footer() {
    return (
        <div className='footer'>
            <img className="transform-logo" src={transformLogoSmall} alt="transform-logo"/>
        </div>
    )
}

export default Footer;
