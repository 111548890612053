import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { goToPage, setPageRequiredQuestions } from "../redux/actions";
import { SECTION } from "../assets/constants";

// Function to get required question ids
const getRequiredQuestionIds = (pageQuestionIds, template) => {
    return template.filter(q => pageQuestionIds.includes(q.element_id)).filter(q => q.required).map(q => q.element_id);
};

// Function to generate pages
const generatePages = (template) => {
    let pages = [];
    let page = {
        index: 1,
        questionIds: [],
    };
    template.forEach(element => {
        if (element.page_break) {
            page.questionIds.push(element.element_id);
            pages.push(page);
            page = {
                index: page.index + 1,
                questionIds: [],
            }
        } else {
            page.questionIds.push(element.element_id);
        }
    });
    if (page.questionIds.length) {
        // push last page if it contains questions
        pages.push(page);
    }
    console.log('pages: ', pages);
    return pages;
};

// Function to count question number
const countQuestionNumber = (question) => {
    return question.element_type !== SECTION && !question.remove_numbering;
};

// Function to get question order
const getQuestionOrder = (question, template) => {
    if (!countQuestionNumber(question)) {
        return '';
    }
    let questionOrder = 1;
    let questionOrderText = '';
    template.forEach(q => {
        if (question.element_id === q.element_id) {
            questionOrderText = `${questionOrder}.`;
            return;
        }
        if (countQuestionNumber(q)) {
            questionOrder++;
        }
    });

    return questionOrderText;
};

// Custom hook to use question container
const useQuestionContainer = (template, props) => {
    const [pages, setPages] = useState([]); // State to store pages
    const [currentPage, setCurrentPage] = useState(null); // State to store current page
    const history = useHistory(); // History hook
    const language = useSelector(state => state.language); // Language state
    const currentPageIndex = useSelector(state => state.currentPageIndex); // Current page index state
    const surveySubmitted = useSelector(state => state.surveySubmitted); // Survey submitted state
    const dispatch = useDispatch(); // Dispatch hook
    const [questionNumeration, setQuestionNumeration] = useState(true); // State to store question numeration
 
    // Effect to update pages and question numeration on template change
    useEffect(() => {
        setPages(generatePages(template));
        if (props.questionNumeration === false) {
            setQuestionNumeration(false);
        }
    }, [template, props.questionNumeration]);

    // Effect to redirect to submitted page on survey submission
    useEffect(() => {
        if (surveySubmitted) {
            history.push('/submitted');
        }
    }, [surveySubmitted, history]);

    // Effect to set current page and required questions on current page index change
    useEffect(() => {
        let curPage = pages.find(p => p.index === currentPageIndex);
        setCurrentPage(curPage);
        if (curPage && curPage.questionIds) {
            const requiredQuestionIds = getRequiredQuestionIds(curPage.questionIds, template);
            dispatch(setPageRequiredQuestions(requiredQuestionIds));
        }
    }, [currentPageIndex, pages, template, dispatch]);

    // Effect to scroll to top on current page index change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPageIndex]);

    // Function to jump to question
    const jumpToQuestion = (questionId) => {
        console.log('jump to question: ', questionId);
        const questionPage = pages.find(page => page.questionIds.includes(questionId));
        if (questionPage) {
            dispatch(goToPage(questionPage.index));
        }
    };

    return {
        pages,
        currentPage,
        language,
        currentPageIndex,
        questionNumeration,
        jumpToQuestion,
        getQuestionOrder: (question) => getQuestionOrder(question, template),
    };
};

export default useQuestionContainer;