import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {useTranslation} from "react-i18next";

import './user-satisfaction.scss';
import {updateQuestionAnswer} from "../../redux/actions";
import {ReactComponent as ExtremelySatisfiedIcon} from "../../assets/images/extremely-satisfied-icon.svg";
import {ReactComponent as VerySatisfiedIcon} from "../../assets/images/very-satisfied-icon.svg";
import {ReactComponent as NeutralIcon} from "../../assets/images/netural-icon.svg";
import {ReactComponent as SlightlySatisfiedIcon} from "../../assets/images/slightly-satisfied-icon.svg";
import {ReactComponent as NotAtAllSatisfiedIcon} from "../../assets/images/not-at-all-satisfied-icon.svg";
import AlertComponent from "../../input-components/alert-component";

const items = [
        {
            item_id: 'multi-item-id-1',
            item_text: 'Svært misfornøyd',
            item_order: 1,
            item_icon: <NotAtAllSatisfiedIcon className='image user-satisfaction-icon'/>,
        },
        {
            item_id: 'multi-item-id-2',
            item_text: 'Misfornøyd',
            item_order: 2,
            item_icon: <SlightlySatisfiedIcon className='image user-satisfaction-icon'/>
        },
        {
            item_id: 'multi-item-id-3',
            item_text: 'Helt Ok',
            item_order: 3,
            item_icon: <NeutralIcon className='image user-satisfaction-icon'/>,
        },
        {
            item_id: 'multi-item-id-4',
            item_text: 'Fornøyd',
            item_order: 4,
            item_icon: <VerySatisfiedIcon className='image user-satisfaction-icon'/>,
        },
        {
            item_id: 'multi-item-id-5',
            item_text: 'Svært fornøyd',
            item_order: 5,
            item_icon: <ExtremelySatisfiedIcon className='image user-satisfaction-icon'/>,
        },
];

function UserSatisfactionQuestion(props) {
    const { template } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useSelector(state => state.user);
    const questionAnswers = useSelector(state => state.questionAnswers);
    const requiredError = useSelector(state => state.requiredError);
    const [answer, setAnswer] = useState(null);

    useEffect(() => {
        const questionAnswer = questionAnswers.find(qa => qa.questionId === template.element_id);
        setAnswer(questionAnswer ? questionAnswer.answer : null);
    }, [template, questionAnswers]);

    const handleSelectChange = (value) => {
        console.log(`select item changed! value: ${value}`);
        setAnswer(value);
        dispatch(updateQuestionAnswer(template.element_id, value, user.token, questionAnswers));
    };

    return (
        <div className='user-satisfaction-question-container'>
            <div className='options images'>
                {
                    items.map((item, index) => {
                        return (
                            <div key={index} className={`image-option ${answer && answer === item.item_text ? 'checked' : ''}`} onClick={() => handleSelectChange(item.item_text)}>
                                {item.item_icon}
                                <span className='image-text'>{item.item_text}</span>
                            </div>
                        )
                    })
                }
            </div>
            {
                requiredError && template.required && !answer &&
                <AlertComponent text={t("required")} type={'error'}/>
            }
        </div>
    );
}

export default UserSatisfactionQuestion;
