// question types
export const TEXT_BOX = 'text_box';
export const SINGLE_LINE_TEXT = 'single_line_text';
export const SINGLE_SELECT = 'single_select';
export const MULTI_SELECT = 'multi_select';
export const SEARCH_CHOOSE = 'search_choose';
export const SINGLE_SLIDER_SINGLE_PICKER = 'single_slider_single_picker';
export const MULTI_SLIDER_SINGLE_PICKER = 'multi_slider_single_picker';
export const SINGLE_SLIDER_TWO_PICKERS = 'single_slider_two_pickers';
export const MULTI_SLIDER_TWO_PICKERS = 'multi_slider_two_pickers';
export const PRIORITY_LIST = 'priority_list';
export const SECTION = 'section';
export const USER_SATISFACTION = 'user_satisfaction';
export const MATRIX_SINGLE_SELECT = 'matrix_single_select';
export const MATRIX_MULTI_SELECT = 'matrix_multi_select';
export const MATRIX_RANK = 'matrix_rank';

// question sub types
export const SINGLE_SELECT_SUBTYPE_TEXT = 'text';
export const SINGLE_SELECT_SUBTYPE_IMAGE = 'image';
export const SINGLE_SELECT_SUBTYPE_TEXT_BUTTON = 'text_button';

// question items
export const ITEMS_SOURCE_TYPE_LIST = 'LIST';
export const ITEMS_SOURCE_TYPE_QUESTION = 'QUESTION';
export const LIST_RESPONDETS = 'respondents';
