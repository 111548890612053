import React from 'react';
import './single-select-question.scss';
import useSingleSelectQuestion from './../../hooks/questions/use-single-select-question';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Chip from '@material-ui/core/Chip';
import AlertComponent from "../../input-components/alert-component";
import { useTranslation } from 'react-i18next';
import { SINGLE_SELECT_SUBTYPE_IMAGE, SINGLE_SELECT_SUBTYPE_TEXT, SINGLE_SELECT_SUBTYPE_TEXT_BUTTON } from "../../assets/constants";
import { getStyle } from "../styleUtil";
import { Box } from "@material-ui/core";
import { colorTheme } from '../../assets/theme';
import { ReactComponent as RadioIcon } from '../../assets/images/radio-icon.svg';

// Make styles for the component
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '20px',
        // '& > *': {
        //     margin: theme.spacing(0.5),
        // },
    },
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    formControlLabel: {
        display: 'flex',
        gap: '12px',
        margin: '0px',
        '& .MuiFormControlLabel-label': {
            fontSize: '15px',
            color: colorTheme.theme.text.mediumGrey
        },
        '& .MuiRadio-root': {
            padding: '0px',
            width: '18px',
            height: '18px',
            '& .MuiSvgIcon-root': {
                transition: 'color 0.3s',
            }
        },
        '&:hover .MuiRadio-root .MuiSvgIcon-root': {
            color: 'var(--colors-highlight)',
        },
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            padding: '0px',
            fontSize: '15px',
            lineHeight: '20px',
            textAlign: 'center',
            '& input': {
                padding: '8px 12px',
                color: colorTheme.theme.text.mediumGrey,
                height: 'initial',
            },
            '& fieldset': {
                borderColor: colorTheme.theme.lines.lightGrey,
                transition: 'border-color 0.3s',
            },
            '&:hover fieldset': {
                borderColor: colorTheme.theme.text.mediumGrey,
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--colors-highlight)',
                borderWidth: '1px',
            },
        },
        '& .MuiInputBase-input::placeholder': {
            color: colorTheme.theme.text.lightGrey,
            textAlign: 'center',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
            transform: 'translate(12px, 8px) scale(1)', // Placeholder stays in place
            fontSize: '15px',
            lineHeight: '20px',
            textTransform: 'none',

            '&.Mui-focused': {
                display: 'none',
            }
        },
    },
    textFieldChip: {
        '&.selected': {
            '& .MuiOutlinedInput-root': {
                backgroundColor: 'var(--colors-highlight)',

                '& input': {
                    fontWeight: '500',
                    color: colorTheme.theme.text.darkGrey
                },
                '& fieldset': {
                    borderColor: 'var(--colors-highlight)',
                },
                '&:hover fieldset': {
                    borderColor: 'var(--colors-highlight)',
                },
            },
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '30px',

            '& input': {
                padding: '10px 24px',
                textAlign: 'center',
            }
        },

        '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
            transform: 'translate(-50%, 10px) scale(1)',
            left: '50%',
        }
    },
    chip: {
        borderRadius: '30px',
        padding: '10px 24px',
        height: 'initial',
        transition: 'box-shadow 0.3s !important',
        border: '1px solid var(--colors-highlight)',
        backgroundColor: 'var(--colors-highlight)',


        '& .MuiChip-label': {
            padding: '0px',
            fontSize: '15px',
            lineHeight: '19px',
            fontWeight: '400',
            textTransform: 'none',
            fontWeight: '600',
            color: colorTheme.theme.text.darkGrey
        },

        '&.MuiChip-outlined': {
            backgroundColor: 'transparent',
            color: colorTheme.theme.text.darkGrey,
            borderColor: 'var(--colors-highlight)',

            '& .MuiChip-label': {
                fontWeight: '500',
            },

            '&:hover': {
                backgroundColor: 'transparent',
            },
        },

        '&:focus': {
            backgroundColor: 'var(--colors-highlight)',
        },

        '&:hover': {
            boxShadow: '0px 2px 4px 0px #7B7D898C',
        },
    }
}));

// SingleSelectQuestion component
const SingleSelectQuestion = (props) => {
    const { t } = useTranslation(); // Get the translation function
    const { template } = props; // Destructure the props object
    const classes = useStyles(); // Get the styles
    const {
        language,
        answer,
        imageAnswer,
        customItem,
        requiredError,
        handleSelectChange,
        handleTextFieldChange
    } = useSingleSelectQuestion(template); // Get the custom hook values

    return template ? (
        <Box className='single-select-question-container' style={{ ...getStyle('single-select-question-container', language), textAlign: 'left' }}>
            {template.style_type === SINGLE_SELECT_SUBTYPE_TEXT && (
                <FormControl component="fieldset" className='options'>
                    <RadioGroup name="text-select" value={answer} onChange={e => handleSelectChange(e.target.value, undefined, SINGLE_SELECT_SUBTYPE_TEXT)} className={classes.formGroup}>
                        {template.items.map((item, index) => (
                            <FormControlLabel key={index} value={item.item_text} control={<Radio className={classes.radio} />} className={classes.formControlLabel} label={item.item_text} />
                        ))}
                        {template.item_other_allowed && (
                            <FormControlLabel
                                value={customItem}
                                control={<Radio />}
                                className={classes.formControlLabel}
                                label={
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        label={customItem ? null : (template.item_placeholder_text ? template.item_placeholder_text : t("other"))}
                                        value={customItem}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleTextFieldChange}
                                        onClick={() => handleSelectChange(customItem)}
                                        InputLabelProps={{ shrink: false }}
                                        className={classes.textField}
                                    />
                                }
                            />
                        )}
                    </RadioGroup>
                </FormControl>
            )}
            {template.style_type === SINGLE_SELECT_SUBTYPE_TEXT_BUTTON && (
                <Box className={`${classes.root} options text-buttons`}>
                    {template.items.map((item, index) => (
                        <Chip
                            key={index}
                            label={item.item_text}
                            value={item.item_text}
                            variant={answer && answer === item.item_text ? 'default' : 'outlined'}
                            onClick={() => handleSelectChange(item.item_text, item.item_id, SINGLE_SELECT_SUBTYPE_TEXT_BUTTON)}
                            classes={{ root: classes.chip }}
                        />
                    ))}
                    {template.item_other_allowed && (
                        <Box display={'flex'} alignItems={'center'}>
                            {/* <RadioGroup name="text-select" onChange={e => handleSelectChange(customItem)} className={classes.formGroup}>
                                <FormControlLabel checked={customItem === answer} control={<Radio />} onClick={() => handleSelectChange(customItem)} className={classes.formControlLabel} />
                            </RadioGroup> */}
                            <TextField
                                className={classes.textField + ' ' + classes.textFieldChip + ' customItem' + (customItem !== "" && customItem === answer ? ' selected' : '')}
                                variant="outlined"
                                size="small"
                                value={customItem}
                                inputProps={{ maxLength: 100 }}
                                InputLabelProps={{ shrink: false }}
                                onChange={handleTextFieldChange}
                                label={customItem ? null : (template.item_placeholder_text ? template.item_placeholder_text : t("other"))}
                                onClick={() => handleSelectChange(customItem)}
                            />
                        </Box>
                    )}
                </Box>
            )}
            {template.style_type === SINGLE_SELECT_SUBTYPE_IMAGE && (
                <Box className='options images' style={{ paddingTop: '16px', display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                    {template.items.map((item, index) => (
                        <Box key={index} className='image-option' onClick={() => handleSelectChange(item.item_text, item.item_id, SINGLE_SELECT_SUBTYPE_IMAGE)} style={{ borderRadus: '4px', border: '1px solid ' + (imageAnswer.id === item.item_id ? 'var(--colors-highlight)' : colorTheme.theme.lines.lightGrey), display: 'flex', flexDirection: 'column', width: '210px', borderRadius: '4px' }}>

                            <Box className='image' style={{ borderBottom: '1px solid ' + colorTheme.theme.lines.lightGrey, position: 'relative', width: '100%', height: '160px', cursor: 'pointer', borderRadius: '4px 4px 0 0' }}>
                                {imageAnswer && imageAnswer.id === item.item_id && (
                                    <RadioIcon className='check-icon' style={{ position: 'absolute', bottom: '7.5px', left: '7.5px', borderRadius: '16px' }} />
                                )}
                                <img src={item.item_url} alt={item.item_text} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </Box>
                            <span className='image-text' style={{ padding: '10px', textAlign: 'center', fontSize: '15px', flexGrow: '1' }}>{item.item_text}</span>
                        </Box>
                    ))}
                    {template.item_other_allowed && (
                        <Box className='custom-item'>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={customItem === answer}
                                        onClick={() => handleSelectChange(customItem)}
                                        value={customItem}
                                        name="custom-item"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                }
                                label={
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        value={customItem}
                                        label={customItem ? null : (template.item_placeholder_text ? template.item_placeholder_text : t("other"))}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleTextFieldChange}
                                        onClick={() => handleSelectChange(customItem)}
                                    />
                                }
                            />
                        </Box>
                    )}
                </Box>
            )}
            {requiredError && template.required && !answer && (
                <AlertComponent text={`**${t("questionRequired")}** ${t("provideAnswer")}`} type='error' />
            )}
        </Box>
    ) : (
        <Box>loading</Box>
    );
};

export default SingleSelectQuestion;
