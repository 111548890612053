import React, {useEffect, useState} from 'react';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import { createBrowserHistory } from 'history';
import {MuiThemeProvider, createTheme, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTranslation} from "react-i18next";
import { ThemeProvider } from "styled-components";
import './App.css';
import NotFoundPage from "./pages/not-found-page";
import Header from "./layout/header/header";
import Footer from "./layout/footer/footer";
import {
    getQuestionResponses,
    getQuestionsTemplate,
    setScreenType,
    setUserToken,
    setPreview,
    getRespondents,
    getMetadata,
    setAnonymousRespondent,
    getCustomLists
} from "./redux/actions";
import QuestionContainer from "./questions/question-container";
import ErrorNotification from "./input-components/error-notification-component";
import IdentityConfirmationPage from "./pages/identity-confirmation-page";
import SurveySubmittedPage from "./pages/submitted-page";
import Loader from "./assets/content-loader";
import {getStyle} from './questions/styleUtil'
import { colorTheme } from './assets/theme'; // Import the colorTheme object from the theme file

const uuid = require('uuid');

function getQueryVariable(variable) {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] === variable) {
            return pair[1]
        }
    }
    return (false);
}

function App() {
    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    const questionsTemplate = useSelector(state => state.questionsTemplate);
    const surveySubmitted = useSelector(state => state.surveySubmitted);
    const user = useSelector(state => state.user);
    const tokenError = useSelector(state => state.tokenError);
    const isPreview = useSelector(state => state.isPreview);
    const companyName = useSelector(state => state.companyName);
    const companyLogo = useSelector(state => state.companyLogo);
    const language = useSelector(state => state.language);
    const colors = useSelector(state => state.colors);
    const userCustom = useSelector(state => state.userCustom);
    const confirmationNote = useSelector(state => state.confirmationNote);
    const confirmNameTitle = useSelector(state => state.confirmNameTitle);
    const confirmNameText = useSelector(state => state.confirmNameText);
    const progressBar = useSelector(state => state.progressBar);
    const questionNumeration = useSelector(state => state.questionNumeration);
    const id = useSelector(state => state.id)
    const [userConfirmed, setUserConfirmed] = useState(false);
    const [anonUser, setAnonUser] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [showFooter, setShowFooter] = useState(false);
    const [isEmbedded, setIsEmbedded] = useState(false);
    const [muiColorTheme, setMuiColorTheme] = useState(colorTheme);

    // use media query hook
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const token = getQueryVariable('token');
    const preview = getQueryVariable('preview');

    useEffect(() => {
        // get token from url
        const surveyId = getQueryVariable('surveyId');
        const anonymous = getQueryVariable('anonymous');
        const embedded = getQueryVariable('embedded');

        if (embedded) {
            setIsEmbedded(true);
            setShowHeader(false);
        }

        if (token) {
            dispatch(setUserToken(token));
            dispatch(setPreview(preview === 'true'));
            fetchData(token, preview);
        } else if (surveyId && anonymous) {
            const anonUserToken = uuid.v4();
            setAnonUser(true);
            dispatch(setAnonymousRespondent(surveyId, anonUserToken));
        }
    }, []);

    useEffect(() => {
        if (user.token && anonUser) {
            fetchData(user.token, isPreview);
        }
    }, [user.token]);

    useEffect(() => {
        if (isMobile && !isEmbedded) {
            setShowFooter(true);
        }
        dispatch(setScreenType(isMobile));
    }, [isMobile]);

    useEffect(() => {
        if (language) {
            console.log('language: ', language);
            i18n.changeLanguage(language);
        }
    }, [language]);

    useEffect(() => {
        if (colors) {
            const updatedTheme = {
                palette: {
                    primary: {
                        main: colors.main,
                    },
                    secondary: {
                        main: colors.highlight,
                    },
                },
            }
            setMuiColorTheme(createTheme(updatedTheme));
            setRootColors(colors.main, colors.highlight);
            }
        }, [colors]);
    
    // Set the root colors for the app
    const setRootColors = (mainColor, highlightColor) => {
        document.documentElement.style.setProperty('--colors-main', mainColor);
        document.documentElement.style.setProperty('--colors-highlight', highlightColor);
    }

    const fetchData = (token, preview) => {
        dispatch(getMetadata(token, preview));
        dispatch(getQuestionsTemplate(token, preview));
        dispatch(getQuestionResponses(token, preview));
        dispatch(getRespondents(token, preview));
        dispatch(getCustomLists(token, preview));
    };

    const identityConfirmed = () => {
        console.log('id confirmed, in app comp');
        setUserConfirmed(true);
    };

    return (
        <div className="App" style={getStyle('App', language)}>
            <MuiThemeProvider theme={muiColorTheme}>
                <ThemeProvider theme={muiColorTheme}>
                    {
                        (userConfirmed || (questionsTemplate && anonUser)) ?
                            <BrowserRouter>
                                <div className="page-container">
                                    {showHeader && <Header isPreview={isPreview} companyLogo={companyLogo}/>}
                                    <Switch>
                                        <Route exact path="/" render={() =>
                                            questionsTemplate ?
                                                <QuestionContainer template={questionsTemplate}
                                                                   progressBar={progressBar}
                                                                   questionNumeration={questionNumeration}/> :
                                                // todo loading screen
                                                <div className='loading-screen'>
                                                    <Loader mobileScreen={isMobile}/>
                                                </div>
                                        }/>
                                        <Route exact path="/submitted" render={() => {
                                            return surveySubmitted ?
                                                <SurveySubmittedPage template={questionsTemplate}/> :
                                                <Route component={NotFoundPage}/>
                                        }}/>
                                        <Route component={NotFoundPage}/>
                                    </Switch>
                                    <ErrorNotification/>
                                    {/* {showFooter && <Footer/>} */}
                                </div>
                            </BrowserRouter> :
                            <IdentityConfirmationPage userName={user ? user.name : undefined}
                                                      identityConfirmed={identityConfirmed} tokenError={tokenError}
                                                      companyName={companyName} companyLogo={companyLogo}
                                                      confirmationNote={confirmationNote} confirmNameTitle={confirmNameTitle} confirmNameText={confirmNameText}/>
                    }
                </ThemeProvider>
            </MuiThemeProvider>
        </div>
    );
}
export default App;

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});
