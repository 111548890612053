import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { questionsAnswered } from "../services/questions-service";
import { SECTION } from "../assets/constants";
import { Box } from '@material-ui/core';

// Function to get the number of answerable questions
const answerableQuestionsNumber = (template) => {
    const answerableQuestions = template.filter(q => {
        return q.element_type !== SECTION;
    });
    return answerableQuestions.length;
};

// ProgressBar component
function ProgressBar(props) {
    const questionAnswers = useSelector(state => state.questionAnswers); // Get questionAnswers from redux store
    const questionsTemplate = useSelector(state => state.questionsTemplate); // Get questionsTemplate from redux store
    const [percentageCompleted, setPercentageCompleted] = useState(0); // Set percentageCompleted to 0
    const [numberOfAnsweredQuestions, setNumberOfAnsweredQuestions] = useState(0); // Set numberOfAnsweredQuestions to 0

    // useEffect hook to update the percentage completed and number of answered questions
    useEffect(() => {
        if (questionsTemplate && questionAnswers) {
            const answeredQuestions = questionsAnswered(questionsTemplate, questionAnswers);
            if (answeredQuestions.length !== numberOfAnsweredQuestions) {
                setNumberOfAnsweredQuestions(answeredQuestions.length);
                const completed = 100 * (answeredQuestions.length / answerableQuestionsNumber(questionsTemplate));
                console.log('percentage completed: ', completed);
                setPercentageCompleted(completed);
            }
        }
    }, [questionsTemplate, questionAnswers]);


    return (
        <Box className={`progress-bar ${props.className ? props.className : ''}`} style={{ display: 'inline-flex', width: '100%', height: '16px', position: 'relative' }}>
            <Box className='completed' style={{ width: `${percentageCompleted}%`, backgroundColor: 'var(--colors-highlight)', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', transition: 'width 0.3s ease-in-out', position: 'absolute', height: '16px' }} />
            <Box className='left' style={{ width: `${100}%`, backgroundColor: 'var(--colors-highlight)', opacity: '0.3' }} />
        </Box>
    )
}

export default ProgressBar;
