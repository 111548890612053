import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { updateQuestionAnswer, addAdditionalItem, removeAdditionalItem } from "../../redux/actions";
import { SINGLE_SELECT_SUBTYPE_IMAGE } from "../../assets/constants";

// Custom hook to handle single select question
const useSingleSelectQuestion = (template) => {
    const dispatch = useDispatch(); // Get the dispatch function from the Redux store 
    const user = useSelector(state => state.user); // Get the user from the Redux store
    const language = useSelector(state => state.language); // Get the language from the Redux store
    const questionAnswers = useSelector(state => state.questionAnswers); // Get the question answers from the Redux store
    const requiredError = useSelector(state => state.requiredError); // Get the required error from the Redux store
    const questionAdditionalItems = useSelector(state => state.questionAdditionalItems); // Get the question additional items from the Redux store
    const [answer, setAnswer] = useState(null); // State to store the answer
    const [imageAnswer, setImageAnswer] = useState({}); // State to store the image answer
    const [customItem, setCustomItem] = useState(''); // State to store the custom item

    // Debounce the update function to avoid multiple API calls
    const delayedUpdate = useCallback(_.debounce(answer => {
        dispatch(updateQuestionAnswer(template.element_id, answer, user.token, questionAnswers))
    }, 300), []);

    // Set the answer and custom item based on the question answers and additional items
    useEffect(() => {
        const questionAnswer = questionAnswers.find(qa => qa.questionId === template.element_id);
        setAnswer(questionAnswer ? questionAnswer.answer : null);
        setCustomItem(questionAdditionalItems[template.element_id] ? questionAdditionalItems[template.element_id][0] : '');
        if (questionAdditionalItems[template.element_id]) {
            setCustomItem(questionAdditionalItems[template.element_id][0]);
        } else if (questionAnswer) {
            if (!template.items.map(i => i.item_text).includes(questionAnswer.answer)) {
                setCustomItem(questionAnswer.answer);
                dispatch(addAdditionalItem(questionAnswer.answer, template.element_id));
            }
        }
    }, [template, questionAnswers]);

    // Handle the select change
    const handleSelectChange = (value, id, source) => {
        if (source === SINGLE_SELECT_SUBTYPE_IMAGE) {
            const selectedImage = { id, value };
            setImageAnswer(selectedImage);
            dispatch(updateQuestionAnswer(template.element_id, selectedImage, user.token, questionAnswers));
        } else {
            setAnswer(value);
            dispatch(updateQuestionAnswer(template.element_id, value, user.token, questionAnswers));
        }
    };

    // Handle the text field change
    const handleTextFieldChange = (event) => {
        const value = event.target.value;
        const oldCustomItem = _.cloneDeep(customItem);
        dispatch(removeAdditionalItem(customItem, template.element_id));
        setCustomItem(value);
        dispatch(addAdditionalItem(value, template.element_id));
        if (oldCustomItem === answer) {
            setAnswer(value);
            delayedUpdate(value);
        }
    };

    // Return the necessary values and functions
    return {
        language,
        answer,
        imageAnswer,
        customItem,
        requiredError,
        handleSelectChange,
        handleTextFieldChange
    };
};

export default useSingleSelectQuestion;
