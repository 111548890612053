import React from 'react';
import { Button, Typography } from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";

// Make style for button
const useStyles = makeStyles(() => ({
    button: (props) => ({
        margin: '0px !important', // For overriding MuiDialogActions-spacing
        padding: '6px 16px',
        borderRadius: '17px',
        boxShadow: 'none',
        backgroundColor: `${props.backgroundColor}`,
        boxShadow: `inset 0px 0px 0px 1.5px ${props.borderColor}`,
        transition: 'background-color 0.3s, box-shadow 0.3s',

        '& .MuiButton-label p': {
            fontSize: '14px',
            lineHeight: '24px',
            color: `${props.textColor}`,
            fontWeight: 500,
            letterSpacing: '0.4px',
        },

        '&:hover': {
            backgroundColor: `${props.backgroundColor}`,
            boxShadow: `inset 0px 0px 0px 1.5px ${props.borderColor}, 0px 2px 8px 0px #7B7D898F`,
        }
    }),
}));

// SecondaryButton component
const SecondaryButton = ({ text, backgroundColor, textColor, borderColor, Icon, onClick }) => {
    const classes = useStyles({ backgroundColor, textColor, borderColor }); // Pass props to useStyles

    return (
        <Button
            variant="contained"
            onClick={onClick}
            className={classes.button}
        >
            {Icon && <Icon style={{ marginRight: '6px' }} />} {/* Add marginRight for Icon */}
            <Typography className='add-button-text'
                sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    transition: 'color 0.3s ease-in-out',
                }}>{text}</Typography>
        </Button>
    )
};

export default SecondaryButton;
