import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateQuestionAnswer, addPageQuestionError, removePageQuestionError } from "../../redux/actions";
import { ITEMS_SOURCE_TYPE_LIST, ITEMS_SOURCE_TYPE_QUESTION } from "../../assets/constants";
import _ from 'lodash';

const uuid = require('uuid'); // For generating unique id

// Constants
const AUTOCOMPLETE_SELECT_OPTION = 'select-option';
const AUTOCOMPLETE_INPUT_CHANGE_INPUT = 'input';
const AUTOCOMPLETE_INPUT_CHANGE_RESET = 'reset';
const AUTOCOMPLETE_INPUT_CHANGE_CLEAR = 'clear';

// Function to remove item from answer
const removeItem = (answer, itemId) => {
    let tempAnswer = _.cloneDeep(answer);
    _.remove(tempAnswer, a => a.item_id === itemId);
    return tempAnswer;
};

// Function to add item to answer
const addItem = (answer, item) => {
    let tempAnswer = _.cloneDeep(answer);
    tempAnswer.push(item);
    return tempAnswer;
};

// Function to update item
const updateItem = (item, attribute, value) => {
    let tempItem = _.cloneDeep(item);
    tempItem[attribute] = value;
    return tempItem;
};

// Custom hook to use search choose question
const useSearchChooseQuestion = (template) => {
    const dispatch = useDispatch(); // Dispatch
    const user = useSelector(state => state.user); // Get user from redux store
    const questionAnswers = useSelector(state => state.questionAnswers); // Get question answers from redux store
    const lists = useSelector(state => state.lists);  // Get lists from redux store
    const requiredError = useSelector(state => state.requiredError); // Get required error from redux store
    const isMobileScreen = useSelector(state => state.isMobileScreen); // Get isMobileScreen from redux store
    const customLists = useSelector(state => state.customLists); // Get customLists from redux store
    const language = useSelector(state => state.language); // Get language from redux store
    const [answer, setAnswer] = useState([]); // State for answer
    const [questionItems, setQuestionItems] = useState(null); // State for question items
    const [newItem, setNewItem] = useState({}); // State for new item
    const [openDialog, setOpenDialog] = useState(false); // State for open dialog
    const [inputValue, setInputValue] = useState(''); // State for input value
    const [autocompleteItems, setAutocompleteItems] = useState([]); // State for autocomplete items
    const [minError, setMinError] = useState(false);
    const pageQuestionErrors = useSelector(state => state.pageQuestionErrors);
    // State for items added by button
    const [itemsAddedByButton, setItemsAddedByButton] = useState(0);

    // Handle open dialog
    const handleClickOpenDialog = () => {
        setNewItem({});
        setOpenDialog(true);
    };

    // Handle close dialog
    const handleDialogClose = () => {
        setNewItem({});
        setOpenDialog(false);
    };



    // useEffect to set answer and question items
    useEffect(() => {
        const questionAnswer = questionAnswers.find(qa => qa.questionId === template.element_id);
        setAnswer(questionAnswer ? questionAnswer.answer : []);

        if (template.items_source_type) {
            if (template.items_source_type === ITEMS_SOURCE_TYPE_LIST) {
                const itemsList = lists.find(l => l.name === template.items_source);
                if (itemsList) {
                    const list = itemsList.list.map(l => { return { ...l, item_id: l.id } });
                    setQuestionItems(list);
                } else {
                    setQuestionItems([]);
                }
            } else if (template.items_source_type === ITEMS_SOURCE_TYPE_QUESTION) {
                const inputItems = questionAnswers.find(qa => qa.questionId === template.items_source);
                if (inputItems && inputItems.answer && !_.isEqual(inputItems.answer, questionItems)) {
                    setQuestionItems(inputItems.answer);
                } else if (!inputItems) {
                    setQuestionItems([]);
                }
            }
        }
    }, [template, questionAnswers, lists]);

    // useEffect to populate autocomplete items
    useEffect(() => {
        if (template.items_source === 'respondents') {
            let respondents = lists[0]?.list ?? [];
            let mappedList = [];
            respondents.forEach(respondent => {
                mappedList.push(respondent.details ?? {});
            });
            populateAutoComplete(mappedList);
        } else {
            let resultList = customLists.filter(e => e.PK === template.items_source_id);
            populateAutoComplete(resultList[0]?.details ?? []);
        }
    }, [customLists, lists]);

    // useEffect for setting min error if items_min_number is set
    useEffect(() => {
        if (template.items_min_number) {
            setMinError(true);
            dispatch(addPageQuestionError(template.element_id));
        }
    }, [template, dispatch]);

    // useEffect for updating min error state
    useEffect(() => {
        if (template.items_min_number) {
            setMinError(answer.length < template.items_min_number);
            if (answer.length < template.items_min_number && !pageQuestionErrors.includes(template.element_id)) {
                dispatch(addPageQuestionError(template.element_id));
            }
            if (answer.length >= template.items_min_number && pageQuestionErrors.includes(template.element_id)) {
                dispatch(removePageQuestionError(template.element_id));
            }
        }
    }, [answer, template, dispatch, pageQuestionErrors]);


    // Function to populate autocomplete items
    const populateAutoComplete = (listItems) => {
        let items = [];
        listItems.forEach(listItem => {
            if (listItem.SURVEY_CONST_ROW_ID) {
                let tmpObj = { item_id: listItem.SURVEY_CONST_ROW_ID };
                template.item_fields.forEach(itemField => {
                    tmpObj[itemField] = listItem[itemField] ?? '';
                });
                items.push(tmpObj);
            }
        });
        setAutocompleteItems(items);
    };

    // Function to handle item change
    const handleItemChange = (event, attribute) => {
        const updatedItem = updateItem(newItem, attribute, event.target.value);
        setNewItem(updatedItem);
    };

    // Function to handle item remove
    const handleItemRemove = (itemId) => {
        const newAnswer = removeItem(answer, itemId);
        setAnswer(newAnswer);
        dispatch(updateQuestionAnswer(template.element_id, newAnswer, user.token, questionAnswers));

        // Check if the removed item was added by the button
        const addedByButton = itemsAddedByButton > 0 ? itemsAddedByButton - 1 : 0;
        setItemsAddedByButton(addedByButton);
    };

    // Function to handle item add
    const handleItemAdd = (event, value, reason) => {
        if (reason === AUTOCOMPLETE_SELECT_OPTION) {
            const newAnswer = addItem(answer, value);
            setAnswer(newAnswer);
            setInputValue('');
            dispatch(updateQuestionAnswer(template.element_id, newAnswer, user.token, questionAnswers));
        }
    };

    // Function to handle new item add
    const handleNewItemAdd = () => {
        const newItemWithId = {
            item_id: uuid.v4(),
            ...newItem
        };
        const newAnswer = addItem(answer, newItemWithId);
        setAnswer(newAnswer);
        dispatch(updateQuestionAnswer(template.element_id, newAnswer, user.token, questionAnswers));
        setOpenDialog(false);

        // Increment the items added by button count
        setItemsAddedByButton(itemsAddedByButton + 1);
    };

    // Function to check if item is in error state
    const isInErrorState = (attribute) => {
        if (template.required_fields && template.required_fields.includes(attribute)) {
            return newItem[attribute] ? newItem[attribute].length === 0 : true;
        }
        return false;
    };

    // Function to check if can submit
    const canSubmit = () => {
        let submit = true;

        if (_.isEmpty(newItem)) {
            submit = false;
        }
        if (template.item_fields) {
            template.item_fields.forEach(field => {
                if (isInErrorState(field)) {
                    submit = false;
                }
            });
        }
        return submit;
    };

    // Function to handle input change
    const onInputChange = (event, value, reason) => {
        if (reason === AUTOCOMPLETE_INPUT_CHANGE_INPUT) {
            setInputValue(value);
        } else if (reason === AUTOCOMPLETE_INPUT_CHANGE_RESET || reason === AUTOCOMPLETE_INPUT_CHANGE_CLEAR) {
            setInputValue('');
        }
    };

    // Return necessary values and functions
    return {
        answer,
        questionItems,
        requiredError,
        isMobileScreen,
        language,
        openDialog,
        newItem,
        inputValue,
        autocompleteItems,
        itemsAddedByButton,
        handleClickOpenDialog,
        handleDialogClose,
        handleItemChange,
        handleItemRemove,
        handleItemAdd,
        handleNewItemAdd,
        isInErrorState,
        canSubmit,
        onInputChange,
        minError,


    };
};

export default useSearchChooseQuestion;
