import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { updateQuestionAnswer, addAdditionalItem, removeAdditionalItem, editAdditionalItem, addPageQuestionError, removePageQuestionError } from "../../redux/actions";
import _ from 'lodash';

const uuid = require('uuid'); // Use for generating unique ids

// Functions for getting new answer
const getNewQuestionAnswer = (answer, value, itemId) => {
    let newAnswer = _.cloneDeep(answer);
    newAnswer.push({
        item_id: itemId,
        item_text: value
    });

    return newAnswer;
};

// Functions for removing answer
const removeAnswer = (answer, itemId) => {
    let newAnswer = _.cloneDeep(answer);
    _.remove(newAnswer, a => a.item_id === itemId);
    return newAnswer;
};
// Functions for updating answer
const updateAnswer = (answer, textBoxValue, itemId) => {
    let tempAnswer = _.cloneDeep(answer);
    const itemIndex = tempAnswer.findIndex(i => i.item_id === itemId);
    tempAnswer[itemIndex].item_text = textBoxValue;
    return tempAnswer;
};

// Functions for updating custom items
const updateCustomItems = (customItems, textBoxValue, itemId) => {
    let tempCustomItems = _.cloneDeep(customItems);
    const itemIndex = tempCustomItems.findIndex(i => i.item_id === itemId);
    tempCustomItems[itemIndex].item_text = textBoxValue;
    return tempCustomItems;
};

// Functions for getting new custom item
const getNewCustomItem = (customItems, textBoxValue, itemId) => {
    const itemIndex = customItems.findIndex(i => i.item_id === itemId);
    if (itemIndex !== -1) {
        return {
            ...customItems[itemIndex],
            item_text: textBoxValue
        }
    }
    return null;
};

// Functions for removing custom item
const removeCustomItem = (customItems, itemId) => {
    let newCustomItems = _.cloneDeep(customItems);
    _.remove(newCustomItems, item => item.item_id === itemId);
    return newCustomItems;
};

// Functions for creating custom item
const createCustomItem = () => {
    return {
        item_id: uuid.v4(),
        item_text: '',
    }
};

// Functions for adding custom item
const addCustomItem = (customItems, item) => {
    let newCustomItems = _.cloneDeep(customItems);
    newCustomItems.push(item);
    return newCustomItems;
};

// Functions for getting additional items
const getAdditionalItems = (answer, templateItems) => {
    const templateItemIds = templateItems.map(i => i.item_id);
    return answer.filter(a => !templateItemIds.includes(a.item_id));
};


// Custom hook for multi-select question
const useMultiSelectQuestion = (template) => {
    const dispatch = useDispatch(); // Get dispatch function from redux
    const questionAnswers = useSelector(state => state.questionAnswers); // Get question answers from redux
    const user = useSelector(state => state.user); // Get user from redux
    const requiredError = useSelector(state => state.requiredError); // Get required error from redux
    const questionAdditionalItems = useSelector(state => state.questionAdditionalItems); // Get additional items from redux
    const pageQuestionErrors = useSelector(state => state.pageQuestionErrors); // Get page question errors from redux
    const language = useSelector(state => state.language); // Get language from redux
    const [answer, setAnswer] = useState([]); // State for answer
    const [customItems, setCustomItems] = useState([]);   // State for custom items
    const [minError, setMinError] = useState(false); // State for min error

    // Debounce function for delayed update
    const delayedUpdate = useCallback(_.debounce(answer => {
        dispatch(updateQuestionAnswer(template.element_id, answer, user.token, questionAnswers))
    }, 300), []);

    // useEffect for setting min error if items_min_number is set
    useEffect(() => {
        if (template.items_min_number) {
            setMinError(true);
            dispatch(addPageQuestionError(template.element_id));
        }
    }, [])

    // useEffect for setting answer and custom items
    useEffect(() => {
        const questionAnswer = questionAnswers.find(qa => qa.questionId === template.element_id);
        setAnswer(questionAnswer ? questionAnswer.answer : []);
        if (questionAdditionalItems[template.element_id]) {
            setCustomItems(questionAdditionalItems[template.element_id]);
        } else if (questionAnswer) {
            // check if additional item fetched from db as answer, if yes add as custom item
            const additionalItems = getAdditionalItems(questionAnswer.answer, template.items);
            setCustomItems(additionalItems);
            additionalItems.forEach(item => { dispatch(addAdditionalItem(item, template.element_id)) });
        }
    }, [template, questionAnswers]);

    // useEffect for setting min error if items_min_number is set
    useEffect(() => {
        if (template.items_min_number) {
            setMinError(answer.length < template.items_min_number);
            if (answer.length < template.items_min_number && !pageQuestionErrors.includes(template.element_id)) {
                dispatch(addPageQuestionError(template.element_id));
            }
            if (answer.length >= template.items_min_number && pageQuestionErrors.includes(template.element_id)) {
                dispatch(removePageQuestionError(template.element_id));
            }
        }
    }, [answer])

    // Function for handling select change
    const handleSelectChange = (itemId, value, checked) => {
        console.log(`select item changed! item id: ${itemId}, value: ${value}, checked: ${checked}`);
        let newAnswer;
        if (checked) {
            newAnswer = getNewQuestionAnswer(answer, value, itemId);
        } else {
            newAnswer = removeAnswer(answer, itemId);
        }
        if (!template.items_max_number || newAnswer.length <= template.items_max_number) {
            setAnswer(newAnswer);
            dispatch(updateQuestionAnswer(template.element_id, newAnswer, user.token, questionAnswers));
        }
    };

    // Function for handling text field change
    const handleTextFieldChange = (event, itemId) => {
        const textValue = event.target.value;
        console.log('custom option change, value: ', textValue);
        if (answer.map(a => a.item_id).includes(itemId)) {
            let newAnswer = updateAnswer(answer, textValue, itemId);
            setAnswer(newAnswer);
            delayedUpdate(newAnswer);
        }
        let newCustomItems = updateCustomItems(customItems, textValue, itemId);
        let newCustomItem = getNewCustomItem(customItems, textValue, itemId);
        dispatch(editAdditionalItem(newCustomItem, template.element_id));
        setCustomItems(newCustomItems);
    };

    // Function for handling item add
    const handleItemAdd = () => {
        console.log('item added');
        const newItem = createCustomItem();
        const newCustomItems = addCustomItem(customItems, newItem);
        dispatch(addAdditionalItem(newItem, template.element_id));
        setCustomItems(newCustomItems);
    };

    // Function for handling item remove
    const handleItemRemove = (itemId) => {
        console.log('item removed, item id: ', itemId);
        const item = customItems.find(i => i.item_id === itemId);
        if (answer.map(a => a.item_id).includes(itemId)) {
            let newAnswer = removeAnswer(answer, itemId);
            setAnswer(newAnswer);
            dispatch(updateQuestionAnswer(template.element_id, newAnswer, user.token, questionAnswers));
        }
        let newCustomItems = removeCustomItem(customItems, itemId);
        dispatch(removeAdditionalItem(item, template.element_id));
        setCustomItems(newCustomItems);
    };

    // useEffect for adding custom item if items_other_allowed is set to 1
    useEffect(() => {
        if(template.items_other_allowed == 1 && customItems.length == 0){
            handleItemAdd();
        }
    }, []);

    // Return necessary values and functions
    return {
        customItems,
        answer,
        requiredError,
        minError,
        language,
        handleSelectChange,
        handleTextFieldChange,
        handleItemAdd,
        handleItemRemove
    }
}

export default useMultiSelectQuestion;