import _ from 'lodash';

import {
    HIDE_ERROR,
    NEXT_PAGE_SELECTED, PAGE_REQUIRED_QUESTIONS_SET,
    PAGE_SELECTED,
    PREVIOUS_PAGE_SELECTED,
    QUESTION_ADDITIONAL_ITEMS_ADD,
    QUESTION_ADDITIONAL_ITEMS_EDIT,
    QUESTION_ADDITIONAL_ITEMS_REMOVE,
    QUESTION_ANSWER_CHANGED,
    QUESTION_RESPONSES_FETCHED,
    SCREEN_TYPE_CHANGE,
    TEMPLATE_FETCHED,
    USER_TOKEN_SET,
    SURVEY_SUBMITTED,
    RESPONDENT_LIST_FETCHED,
    CUSTOM_LIST_FETCHED,
    PAGE_QUESTION_ERROR_CHANGE,
    CLICKED_SUBMIT,
    METADATA_FETCHED,
    METADATA_ERROR,
    PAGE_QUESTION_ERROR_ADD,
    PAGE_QUESTION_ERROR_REMOVE,
    PREVIEW_SET,
    RESPONDENTS_STATUS_CHANGE,
} from "../../assets/action-constants";
import {LIST_RESPONDETS} from "../../assets/constants";
import {pageCompleted} from "../../services/questions-service";

const initialState = {
    questionsTemplate: null,
    user: {},
    currentPageIndex: 1,
    questionAnswers: [],
    questionAdditionalItems: {},
    pageRequiredQuestions: [],
    pageQuestionErrors: [],
    error: null,
    isErrorOpen: false,
    lists: [],
    isPreview: false,
    respondentsStatus: false,
};

export default (state = initialState, action) => {
    const { error } = action;

    if(error){
        return Object.assign({}, state, {
            error: error,
            isErrorOpen: true,
        });
    }

    if(action.type === HIDE_ERROR) {
        return Object.assign({}, state, {
            error: null,
            isErrorOpen: false
        });
    }

    if(action.type === TEMPLATE_FETCHED) {
        const {questions} = action.payload;
        const templateSorted = _.sortBy(questions, q => q.element_order);
        return Object.assign({}, state, {
            questionsTemplate: templateSorted,
            templateFetched: true,
        });
    }

    if(action.type === QUESTION_ANSWER_CHANGED) {
        const {questionId, answer} = action.payload;
        const questionAnswers = _.cloneDeep(state.questionAnswers);
        const questionAnswerIndex = state.questionAnswers.findIndex(qa => qa.questionId === questionId);
        questionAnswerIndex !== -1 ?
            questionAnswers.splice(questionAnswerIndex, 1, {questionId, answer}) :
            questionAnswers.push({questionId, answer});
        return Object.assign({}, state, {
            questionAnswers
        });
    }

    if(action.type === PREVIOUS_PAGE_SELECTED) {
        return Object.assign({}, state, {
            currentPageIndex: state.currentPageIndex - 1,
            pageRequiredQuestions: [],
            pageQuestionErrors: [],
            requiredError: false,
        });
    }

    if(action.type === NEXT_PAGE_SELECTED) {
        if (pageCompleted(state.pageRequiredQuestions, state.questionAnswers, state.pageQuestionErrors)) {
            console.log('required questions answered!');
            return Object.assign({}, state, {
                currentPageIndex: state.currentPageIndex + 1,
                pageRequiredQuestions: [],
                pageQuestionErrors: [],
                requiredError: false,
            });
        }
        console.log('not all req qs answered, set error!');
        return Object.assign({}, state, {
            requiredError: true,
        });
    }

    if(action.type === PAGE_SELECTED) {
        console.log('page selected: ', action.payload);
        if (
            action.payload < state.currentPageIndex ||
            pageCompleted(state.pageRequiredQuestions, state.questionAnswers, state.pageQuestionErrors)
           ) {
            return Object.assign({}, state, {
                currentPageIndex: action.payload,
                pageRequiredQuestions: [],
                pageQuestionErrors: [],
                requiredError: false,
            });
        }
        return Object.assign({}, state, {
            requiredError: true,
        });
    }

    if (action.type === CLICKED_SUBMIT) {
        // clicked submit but page is incomplete
        return Object.assign({}, state, {
            requiredError: true,
        });
    }

    if (action.type === SURVEY_SUBMITTED) {
        return Object.assign({}, state, {
            pageRequiredQuestions: [],
            pageQuestionErrors: [],
            requiredError: false,
            surveySubmitted: true,
        });
    }

    if(action.type === PAGE_REQUIRED_QUESTIONS_SET) {
        console.log('set req question ids: ', action.payload);
        return Object.assign({}, state, {
            pageRequiredQuestions: action.payload,
        });
    }

    if(action.type === QUESTION_ADDITIONAL_ITEMS_ADD) {
        const {item, questionId} = action.payload;
        let tempAdditionalItems = _.cloneDeep(state.questionAdditionalItems);
        if (tempAdditionalItems[questionId]) {
            tempAdditionalItems[questionId].push(item);
        } else {
            tempAdditionalItems[questionId] = [item];
        }

        return Object.assign({}, state, {
            questionAdditionalItems: tempAdditionalItems,
        });
    }

    if(action.type === QUESTION_ADDITIONAL_ITEMS_REMOVE) {
        const {item, questionId} = action.payload;
        let tempAdditionalItems = _.cloneDeep(state.questionAdditionalItems);
        if (tempAdditionalItems[questionId]) {
            let tempArray = tempAdditionalItems[questionId];
            item.item_id ?
                _.remove(tempArray, i => i.item_id === item.item_id) :
                _.pull(tempArray, item);
            tempAdditionalItems[questionId] = tempArray;

            console.log(item);
            console.log(tempAdditionalItems);

            return Object.assign({}, state, {
                questionAdditionalItems: tempAdditionalItems,
            });
        }
        return state;
    }

    if(action.type === QUESTION_ADDITIONAL_ITEMS_EDIT) {
        const {item, questionId} = action.payload;
        let tempAdditionalItems = _.cloneDeep(state.questionAdditionalItems);
        if (tempAdditionalItems[questionId]) {
            const itemIndex = tempAdditionalItems[questionId].findIndex(i => i.item_id === item.item_id);
            if (itemIndex !== -1) {
                tempAdditionalItems[questionId][itemIndex] = item;

                return Object.assign({}, state, {
                    questionAdditionalItems: tempAdditionalItems,
                });
            }
        }
        return state;
    }

    if(action.type === SCREEN_TYPE_CHANGE) {
        const {isMobile} = action.payload;
        return Object.assign({}, state, {
            isMobileScreen: isMobile,
        });
    }

    if(action.type === USER_TOKEN_SET) {
        const {token} = action.payload;
        const newUser = _.cloneDeep(state.user);
        newUser.token = token;
        return Object.assign({}, state, {
            user: newUser,
        });
    }

    if(action.type === PREVIEW_SET) {
        const {preview} = action.payload;
        return Object.assign({}, state, {
            preview: preview
        });
    }

    if (action.type === QUESTION_RESPONSES_FETCHED) {
        return Object.assign({}, state, {
            questionAnswers: action.payload
        });
    }

    if (action.type === RESPONDENT_LIST_FETCHED) {
        console.log('respondent list fetched: ', action.payload);
        const respondentList = {
            name: LIST_RESPONDETS,
            list: action.payload,
        };
        return Object.assign({}, state, {
            lists: [...state.lists, respondentList]
        });
    }

    if (action.type === CUSTOM_LIST_FETCHED) {
        console.log('custom list fetched into redux state: ', action.payload);
        const {lists} = action.payload
        return Object.assign({}, state, {
            customLists: lists
        }); 
    }

    if (action.type === METADATA_FETCHED) {
        console.log('metadata fetched: ', action.payload);
        const {preview, id, details: {respondentName, confirmNameTitle, confirmNameText, userCustom, companyName, companyLogo, colors, language, graph, progressBar, questionNumeration, confirmationNote}} = action.payload;
        return Object.assign({}, state, {
            id: id,
            isPreview: preview,
            user: {
                ...state.user,
                name: respondentName,
            },
            companyName,
            companyLogo,
            colors,
            language,
            graph,
            progressBar,
            questionNumeration,
            confirmationNote,
            confirmNameTitle,
            confirmNameText,
            userCustom
        });
    }

    if (action.type === METADATA_ERROR) {
        console.log('metadata error: ', action.payload);
        const {message} = action.payload;
        return Object.assign({}, state, {
            tokenError: message,
        });
    }

    if (action.type === PAGE_QUESTION_ERROR_CHANGE) {
        const {pageQuestionErrors} = action.payload;
        console.log('page question errors: ', pageQuestionErrors);
        return Object.assign({}, state, {
            pageQuestionErrors,
        });
    }

    if (action.type === PAGE_QUESTION_ERROR_ADD) {
        const {questionId} = action.payload;
        console.log('page question error add: ', questionId);
        return Object.assign({}, state, {
            pageQuestionErrors: state.pageQuestionErrors.includes(questionId) ? state.pageQuestionErrors : state.pageQuestionErrors.concat(questionId),
        });
    }

    if (action.type === PAGE_QUESTION_ERROR_REMOVE) {
        const {questionId} = action.payload;
        console.log('page question error remove: ', questionId);
        return Object.assign({}, state, {
            pageQuestionErrors: _.without(state.pageQuestionErrors, questionId),
        });
    }

    if (action.type === RESPONDENTS_STATUS_CHANGE) {
        const {respondentsStatus} = action.payload;
        return Object.assign({}, state, {
            respondentsStatus,
        });
    }

    return state;
};
